import React, { useCallback, useEffect, useMemo, useState } from "react";
import Input from "../../../components/ui/common/Input";
import Button from "../../ui/common/Button";
import ToggleSwitch from "../../../components/ui/toggleSwitch/ToggleSwitch";
import { SettingsIcon } from "../../../icons/Icons";
import { FEATURES } from "./Features";
import DomainStatusSettings from "./DomainStatusSettings";
import CopyConfiguration from "./CopyConfiguration";
import Modal from "../../ui/modal/Modal";
import StatisticsSettings from "./StatisticsSettings";
import AiModal from "./submodals/AiModal";
import WavesModal from "./submodals/WavesModal";
import InsightsSettingsModal from "./submodals/InsightsSettingsModal";
import StoriesSettingsModal from "./submodals/StoriesSettingsModal";
import TentaclesSettingsModal from "./submodals/TentaclesSettingsModal";
import VideosModal from "./submodals/VideosModal";
import { updateBrand } from "../../../store/slices/brands";
import { useAppDispatch } from "../../../store";
import "./EditBrandModal.scss";
import "./submodals/SubModalGeneral.scss";

type EditBrandModalProps = {
  brand: any;
  newBrand: boolean;
};

const EditBrandModal = ({ brand, newBrand }: EditBrandModalProps) => {
  const dispatch = useAppDispatch();

  const [showDomainStatusSettings, setShowDomainStatusSettings] = useState(false);
  const [showCopyConfiguration, setShowCopyConfiguration] = useState(false);
  const [showStatisticsSettings, setShowStatisticsSettings] = useState(false);
  const [subModalOpen, setSubModalOpen] = useState(false);
  const [activeSubModal, setActiveSubModal] = useState("");

  const [brandActive, setBrandActive] = useState(brand?.active?.value);
  const [brandFeatures, setBrandFeatures] = useState(brand?.features?.value || []);

  const [brandName, setBrandName] = useState(brand?.name?.value);
  const [timezone, setTimezone] = useState(brand?.timezone?.value);
  const [readingDirection] = useState(brand?.readingDirection?.value || brand?.readingDirection?.default);
  const [trackingDays, setTrackingDays] = useState(brand?.trackingDays?.value || brand?.trackingDays?.default);
  const [primaryStoryType, setPrimaryStoryType] = useState(
    brand?.primaryStoryType?.value || brand?.primaryStoryType?.default,
  );

  const [aiSettings, setAiSettings] = useState(brand.aiSettings);
  const [insightsSettings, setInsightsSettings] = useState(brand.insightsSettings);
  const [storiesSettings, setStoriesSettings] = useState(brand.storiesSettings);
  const [tentaclesSettings, setTentaclesSettings] = useState(brand.tentaclesSettings);
  const [videosSettings, setVideosSettings] = useState(brand.videosSettings);
  const [wavesSettings, setWavesSettings] = useState(brand.wavesSettings);

  const [brandLogoSrc, setBrandLogoSrc] = useState(brand.brandlogo?.value || brand.brandlogo?.default);

  const handleToggleDomainStatus = () => {
    if (showDomainStatusSettings) {
      setShowDomainStatusSettings(false);
    } else {
      setShowDomainStatusSettings(true);
      setShowCopyConfiguration(false);
      setShowStatisticsSettings(false);
    }
  };

  const handleToggleCopyConfiguration = () => {
    if (showCopyConfiguration) {
      setShowCopyConfiguration(false);
    } else {
      setShowDomainStatusSettings(false);
      setShowCopyConfiguration(true);
      setShowStatisticsSettings(false);
    }
  };

  const handleToggleStatistics = () => {
    if (showStatisticsSettings) {
      setShowStatisticsSettings(false);
    } else {
      setShowStatisticsSettings(true);
      setShowDomainStatusSettings(false);
      setShowCopyConfiguration(false);
    }
  };

  const handleSaveAiSettings = (data) => {
    setAiSettings(data);
  };

  const handleSaveInsightsSettings = (data) => {
    setInsightsSettings(data);
  };

  const handleSaveStoriesSettings = (data) => {
    setStoriesSettings(data);
  };

  const handleSaveTentaclessSettings = (data) => {
    setTentaclesSettings(data);
  };

  const handleSaveVideosSettings = (data) => {
    setVideosSettings(data);
  };

  const handleSaveWavesSettings = (data) => {
    setWavesSettings(data);
  };

  const closeSubModal = () => {
    setActiveSubModal("");
    setSubModalOpen(false);
  };

  const currentSubModal = useMemo(() => {
    switch (activeSubModal) {
      case "insights":
        return (
          <InsightsSettingsModal data={insightsSettings} onSave={handleSaveInsightsSettings} onClose={closeSubModal} />
        );
      case "stories":
        return (
          <StoriesSettingsModal data={storiesSettings} onSave={handleSaveStoriesSettings} onClose={closeSubModal} />
        );
      case "tentacles":
        return (
          <TentaclesSettingsModal
            data={tentaclesSettings}
            onSave={handleSaveTentaclessSettings}
            onClose={closeSubModal}
          />
        );
      case "videos":
        return <VideosModal data={videosSettings} onSave={handleSaveVideosSettings} onClose={closeSubModal} />;
      case "waves":
        return <WavesModal data={wavesSettings} onSave={handleSaveWavesSettings} onClose={closeSubModal} />;
      case "ai":
        return <AiModal data={aiSettings} onSave={handleSaveAiSettings} onClose={closeSubModal} />;

      default:
        return <div></div>;
    }
  }, [activeSubModal]);

  const handleFeatureSwitch = useCallback(
    (clickedFeature) => {
      if (brandFeatures.includes(clickedFeature)) {
        setBrandFeatures(
          brandFeatures.filter((feature) => {
            return feature !== clickedFeature;
          }),
        );
      } else {
        setBrandFeatures([...brandFeatures, clickedFeature]);
      }
    },
    [brandFeatures],
  );

  const handleFileUpdate = (e) => {
    // @ts-ignore
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      setBrandLogoSrc(reader.result)
    })

    if (file) {
      reader.readAsDataURL(file);
    }
    
  }

  const mapValuesToBaseAttr = (objectToMap) => {
    const newObject = { ...objectToMap };
    for (const key in newObject) {
      if (Object.prototype.hasOwnProperty.call(newObject, key)) {
        newObject[key] = newObject[key].value || newObject[key].default;
      }
    }
    return newObject;
  };

  const handleSaveBrand = () => {
    const dataToSave = {
      active: brandActive,
      aiSettings: mapValuesToBaseAttr(aiSettings),
      apiToken: brand.apiToken.value || brand.apiToken.default,
      brandlogo: brandLogoSrc,
      // coral reef URL ???
      features: brandFeatures,
      insightsSettings: mapValuesToBaseAttr(insightsSettings),
      name: brandName,
      primaryStoryType: primaryStoryType,
      readingDirection: readingDirection,
      storiesSettings: mapValuesToBaseAttr(storiesSettings), // storyKpiPercentile ..??
      tentaclesSettings: {
        abTestSettings: mapValuesToBaseAttr(tentaclesSettings.abTestSettings),
        classifications: mapValuesToBaseAttr(tentaclesSettings.classifications),
        settings: mapValuesToBaseAttr(tentaclesSettings.settings),
        tentaclesInfo: mapValuesToBaseAttr(tentaclesSettings.tentaclesInfo),
      },
      timezone: timezone,
      trackingDays: trackingDays,
      videosSettings: mapValuesToBaseAttr(videosSettings),
      wavesSettings: mapValuesToBaseAttr(wavesSettings),
    };

    dispatch(updateBrand(brand.id.value, dataToSave))
  };

 

  return (
    <div className="edit-brand-modal-wrapper">
      <Modal
        isOpen={subModalOpen}
        onClose={() => {
          setSubModalOpen(false);
        }}
      >
        {currentSubModal}
      </Modal>
      <h1 className="modal-title">Edit Brand </h1>

      <div className="edit-brand-container">
        <div className="edit-brand-details">
          <div className="brand-active-switch-container">
            <span className="toggle-switch-label"> Brand Active</span>
            <ToggleSwitch
              checked={brandActive}
              onChange={(e) => {
                setBrandActive(e);
              }}
            />
          </div>
          <Input
            id="id"
            label="Brand Id"
            value={brand?.id?.value || brand?.id?.default || ""}
            separateLines
            fullWidth
            readOnly={!newBrand}
            disabled={!newBrand}
          />
          {!newBrand && (
            <Input
              id="token"
              label="API token"
              value={brand?.apiToken?.value || brand?.apiToken?.default}
              onChange={() => {}}
              separateLines
              fullWidth
              readOnly
              disabled
            />
          )}
          <Input
            id="name"
            label="Brand name"
            value={brandName}
            onChange={(e) => {
              setBrandName(e.target.value);
            }}
            separateLines
            fullWidth
          />
          <Input
            id="timezone"
            label="Timezone"
            value={timezone}
            onChange={(e) => {
              setTimezone(e.target.value);
            }}
            separateLines
            fullWidth
          />
          <Input
            id="readingDirection"
            label="Reading Direction"
            value={readingDirection === "ltr" ? "Left to right" : "Right to left"}
            onChange={() => {}}
            separateLines
            fullWidth
            readOnly
            disabled
          />
          <Input
            id="trackingDays"
            type="number"
            label="Tracking Days"
            value={trackingDays}
            onChange={(e) => {
              setTrackingDays(e.target.valueAsNumber);
            }}
            separateLines
            fullWidth
          />
          <Input
            id="primaryStoryType"
            label="Primary story type"
            value={primaryStoryType}
            onChange={(e) => {
              setPrimaryStoryType(e);
            }}
            separateLines
            fullWidth
          />
        </div>
        <div className="brand-features">
          <h3>Features</h3>
          <div className="brand-features-container">
            <ul className="features-list">
              {FEATURES.map((feature) => {
                return (
                  <li className="feature-item" key={feature.name}>
                    <div className="feature-name">{feature.name.toLocaleLowerCase()}</div>
                    <div className="feature-toggle">
                      <ToggleSwitch
                        checked={brandFeatures.includes(feature.name)}
                        disabled={false}
                        onChange={() => {
                          handleFeatureSwitch(feature.name);
                        }}
                      />
                    </div>
                    <div className="feature-settings-icon">
                      {feature.hasSettings ? (
                        // @ts-ignore
                        <SettingsIcon
                          color={brandFeatures.includes(feature.name) ? "#1abcae" : "gray"}
                          width="30"
                          onClick={() => {
                            if (!brandFeatures.includes(feature.name)) return false;
                            setActiveSubModal(feature.name);
                            setSubModalOpen(true);
                          }}
                        />
                      ) : (
                        <div className="height-corrector"></div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="other-settings">
          <div className="brand-logo-wrapper">
            <div className="brand-logo-container">
              <img src={brandLogoSrc} alt={`${brandName}_logo`} />
            </div>
            <div className="select-logo">
              <input type="file" id="brand-logo" onChange={handleFileUpdate}/>
            
              <label
                htmlFor="brand-logo"
              >
                Select new logo
              </label>
            </div>
          </div>

          <DomainStatusSettings
            handleToggleDomainStatus={handleToggleDomainStatus}
            showDomainStatusSettings={showDomainStatusSettings}
          />
          <CopyConfiguration
            showCopyConfiguration={showCopyConfiguration}
            handleToggleCopyConfiguration={handleToggleCopyConfiguration}
          />
          <StatisticsSettings
            showStatisticsSettings={showStatisticsSettings}
            handleToggleStatistics={handleToggleStatistics}
          />
        </div>
      </div>
      <div className="sub-modal-actions">
        <Button variant="danger">Cancel</Button>
        <Button
          variant="success"
          onClick={() => {
            handleSaveBrand();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default EditBrandModal;
