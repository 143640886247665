import React, { useEffect, useMemo, useState } from "react";
import MainNavigation from "../../../components/navigation/MainNavigation";
import ImpactRadarGraph from "../../../components/graphs/ImpactRadarGraph";
// import { data } from "./MockData";
import { useAppDispatch } from "../../../store";
import { fetchImpact } from "../../../store/slices/impact";
import { useSelector } from "react-redux";
import "./impactRadar.scss";
import WeekPicker from "../../../components/ui/weekPicker/WeekPicker";
import { DateTime } from "luxon";

const CHANNELS = {
  TOTAL: "total",
  FACEBOOK: "facebook",
  LINKEDIN: "linkedin",
  MOBILE: "mobile",
  NEWSLETTER: "newsletter",
  TWITTER: "twitter",
  YOUTUBE: "youtube",
  VIDEO: "video",
};

const ImpactRadar = () => {
  const [week, setWeek] = useState({ firstDay: "", lastDay: "" });
  const [channel, setChannel] = useState(CHANNELS.TOTAL);
  const currentBrand = useSelector((state: any) => state.currentBrand.data);
  const dispatch = useAppDispatch();
  const data = useSelector((state: any) => state.impact?.data.graphdata) || [];
  const dataLoading = useSelector((state: any) => state.impact?.isLoading);

  useEffect(() => {
    document.title = "Impact Radar";
  }, []);

  const hourTohourScale = (hour, startHour) => {
    let startHourD = startHour || 6;
    return ((hour + (24 - startHour)) % 24) + startHourD;
  };

  useEffect(() => {
    if (!week) return;
    if (week.firstDay === "" || week.lastDay === "") return;
    const dateObjectFrom = new Date(week?.firstDay);
    // const dateTo =
    let dateObjectTo = new Date(week?.lastDay);
    dateObjectTo.setDate(dateObjectTo.getDate() + 1);

    const yearFrom = dateObjectFrom.getFullYear();
    const monthFrom = String(dateObjectFrom.getMonth() + 1).padStart(2, "0");
    const dayFrom = String(dateObjectFrom.getDate()).padStart(2, "0");

    const yearTo = dateObjectTo.getFullYear();
    const monthTo = String(dateObjectTo.getMonth() + 1).padStart(2, "0");
    const dayTo = String(dateObjectTo.getDate()).padStart(2, "0");

    const formattedDateFrom = `${yearFrom}-${monthFrom}-${dayFrom}`;
    const formattedDateTo = `${yearTo}-${monthTo}-${dayTo}`;
    dispatch(fetchImpact(formattedDateFrom, formattedDateTo, channel));
  }, [week, dispatch, channel]);

  const impactData = useMemo(() => {
    return { graphdata: data };
  }, [data]);

  const transformedEngagementData = useMemo(() => {
    if (impactData.graphdata.length === 0) return [];
    const engagementData = impactData?.graphdata[1]?.data;
    let transformedData = [] as Array<any>;

    for (const timestamp in engagementData) {
      if (engagementData.hasOwnProperty(timestamp)) {
        const dt = DateTime.fromMillis(parseInt(timestamp)).setZone(currentBrand.domainSettings.timezone);
        const day = dt.weekday - 1; // Sunday is 0, so subtract 1 for consistency with Date.getDay()
        const hour = dt.hour;
        const value = engagementData[timestamp];
        const dateD = dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        let dateObjectTo = new Date(week?.lastDay);
        dateObjectTo.setDate(dateObjectTo.getDate() + 1);
        dateObjectTo.setHours(6, 0, 0, 0);

        if (dt < dateObjectTo) {
          transformedData.push({
            date: dt,
            day: day,
            hour: hourTohourScale(hour, 6),
            value: value,
            timestamp: timestamp,
            dateD: dateD,
          });
        }
      }
    }
    return transformedData;
  }, [impactData]);

  const transformedImpactData = useMemo(() => {
    if (impactData.graphdata.length === 0) return [];
    const impactDataOnly = impactData?.graphdata[2]?.data;
    let transformedData = [] as Array<any>;

    for (const timestamp in impactDataOnly) {
      if (impactDataOnly.hasOwnProperty(timestamp)) {
        const dt = DateTime.fromMillis(parseInt(timestamp)).setZone(currentBrand.domainSettings.timezone);
        const day = dt.weekday - 1; // Sunday is 0, so subtract 1 for consistency with Date.getDay()
        const hour = dt.hour;
        const value = impactDataOnly[timestamp];
        const dateD = dt.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        let dateObjectTo = new Date(week?.lastDay);
        dateObjectTo.setDate(dateObjectTo.getDate() + 1);
        dateObjectTo.setHours(6, 0, 0, 0);

        if (dt < dateObjectTo) {
          transformedData.push({
            date: dt,
            day: day,
            hour: hourTohourScale(hour, 6),
            value: value,
            timestamp: timestamp,
            dateD: dateD,
          });
        }
      }
    }
    return transformedData;
  }, [impactData]);

  const channelsArray = Object.values(CHANNELS);

  const handleWeekChange = (week) => {
    setWeek(week);
  };

  const startDate = useMemo(() => {
    if (impactData.graphdata.length === 0) return "";
    // @ts-ignore
    return new Date(parseInt(impactData.graphdata[0]?.startdate, 10));
  }, [impactData]);

  const endDate = useMemo(() => {
    if (impactData.graphdata.length === 0) return "";
    // @ts-ignore
    // const date =
    return new Date(parseInt(impactData.graphdata[0]?.enddate, 10));
  }, [impactData]);

  return (
    <>
      <MainNavigation />
      <div className="impact-radar-page">
        <h1>Impact Radar</h1>
        <WeekPicker onChange={handleWeekChange} />
        {/* <input type="week" /> */}
        <div className="channels-wrapper">
          <p>Channels</p>
          <div className="channels-container">
            {channelsArray.map((channel) => {
              return (
                <div
                  className="channel-name"
                  onClick={() => {
                    setChannel(channel);
                  }}
                >
                  <span className={["channel-indicator-icon", `channel-base-color-${channel}`].join(" ")}></span>
                  {channel}
                </div>
              );
            })}
            <div className="channel-name">
              <span className={["channel-indicator-icon", `channel-base-color-all`].join(" ")}></span>
              All (reset)
            </div>
          </div>
        </div>
        <div className="impact-radar-chart-container">
          {impactData.graphdata.length > 0 && !dataLoading && (
            <ImpactRadarGraph
              data={impactData.graphdata}
              effortData={transformedEngagementData}
              impactData={transformedImpactData}
              startDate={startDate}
              endDate={endDate}
              selectedChannel={channel}
            />
          )}
        </div>
        <div className="impact-radar-legend">
          <span className={`color-${channel}-effort`}></span>Effort
          <span className={`color-${channel}-impact`} style={{ marginLeft: 20 }}></span>Impact
        </div>
      </div>
    </>
  );
};

export default ImpactRadar;
