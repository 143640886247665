import { useRef, useEffect, useCallback, useState } from "react";
import { createRoot } from "react-dom/client";
import { TimezoneNight } from "../../../icons/Icons";
import {
  select,
  axisBottom,
  axisLeft,
  scaleLinear,
  stack,
  max,
  area,
  curveBasis,
  scaleTime,
  timeDay,
  timeHours,
  timeHour,
  timeSaturdays,
  extent as d3Extent,
  zoom,
  zoomIdentity,
} from "d3";

type StackedBarChartProps = {
  data: any,
  keys: any,
  colors: any,
  icons: any,
};

const StackedAreaGraph = ({ data, keys, colors, icons, initialWidth }: StackedBarChartProps) => {
  const svgRef = useRef(null);

  const [forceRerender, setForceRerender] = useState(0);

  const width = initialWidth || 780;
  const height = 300;
  const marginLeft = 20;
  const marginRight = 40;
  const marginBottom = 20;

  const eveningHours = { start: 0, length: 6 };

  const createNightRectangles = useCallback(
    (xScale, svg) => {
      if (data.length === 0) return;

      const weekendThreshold = 5 * 24;
      let eveningSelection;
      let eveningEnter, eveningWidth;

      let extentD = xScale.domain();

      const hours = (extentD[1].getTime() - extentD[0].getTime()) / (1000 * 60 * 60);

      if (hours < weekendThreshold) {
        extentD[0] = timeDay.offset(extentD[0], -1);
        extentD[1] = timeDay.offset(extentD[1], 1);
        eveningSelection = timeHours(...extentD).filter((d) => eveningHours.start === d.getHours());
      } else {
        extentD[0] = timeDay.offset(extentD[0], -4);
        extentD[1] = timeDay.offset(extentD[1], 4);
        eveningSelection = timeSaturdays(...extentD);
      }

      // Evening rectangles
      const eveningGroup = svg.selectAll(".evening").data(eveningSelection, (d) => d.getTime());

      eveningEnter = eveningGroup.enter().append("g").attr("class", "evening");
      eveningEnter.append("rect").attr("y", 0);

      const nightIconGroup = eveningEnter
        .append("g")
        .attr("class", "night-icon")
        .attr("transform", (d, i) => `translate(${i * 20}, 15)`);

      // nightIconGroup.each(function (d, i) {
      //   ReactDOM.render(<TimezoneNight />, this);
      // });
      nightIconGroup.each(function (d, i) {
        const root = createRoot(this); // Create a root for each DOM element
        root.render(<TimezoneNight />); // Render the component
      });

      eveningGroup.exit().remove();

      eveningWidth = (d) => {
        return xScale(timeHour.offset(d, eveningHours.length)) - xScale(d);
      };

      // const weekendWidth = (d) => xScale(timeDay.offset(d, 2)) - xScale(d);

      eveningGroup
        .selectAll("rect")
        .attr("width", (d) => {
          // let hours = (extentD[1].getTime() - extentD[0].getTime()) / (1000 * 60 * 60);
          return eveningWidth(d);
        })
        .attr("height", height - 20)
        .style("opacity", 0.1)
        .attr("x", (d) => xScale(d));

      eveningGroup
        .selectAll(".night-icon")
        .style("display", (d) => (hours < weekendThreshold ? "block" : "none"))
        .attr("transform", (d) => {
          const xVal = xScale(d) + 5;
          const width = eveningWidth(d);
          const xAcc = xVal + width;
          let x;
          if (xVal < 50 && xAcc > 90) {
            x = 50;
          } else if (xVal < 50) {
            x = xAcc - 40;
          } else {
            x = xVal;
          }
          const y = 15; // Adjust the y value as needed
          return `translate(${x}, ${y})`;
        });
    },
    [eveningHours.length, eveningHours.start, data, forceRerender],
  );

  const displayPostIcons = useCallback(
    (xScale, svg) => {
      var postSize = 20;

      /* Calculate post heights */

      const iconsToDisplay = [];

      icons
        .map((post) => ({ ...post }))
        .reduce((prev, p) => {
          let occupied = [];
          p.x = xScale(p.date);
          p.prev = prev;
          while (prev !== null && Math.abs(prev.x - p.x) < postSize) {
            occupied.push(prev.height);
            prev = prev.prev;
          }
          p.height = 40;
          while (occupied.indexOf(p.height) >= 0) {
            p.height += 20;
          }
          iconsToDisplay.push(p);
          return p;
        }, null);

      // Show all posts

      if (iconsToDisplay?.length > 0) {
        //First remove all posts from the graph then build them as new...
        svg.selectAll(".post").remove();

        let postSelection = svg.selectAll(".post").data(iconsToDisplay);

        postSelection.exit().remove();

        let postEnter = postSelection
          .enter()
          .insert("g", ".post")
          .attr("class", function (d) {
            return "post v-" + d.type;
          });

        postEnter.append("line").attr("x1", 0).attr("x2", 0).attr("y1", 0).attr("stroke", "#666");

        postEnter
          .append("circle")
          .attr("class", "top")
          .attr("r", postSize / 2)
          .attr("fill", "#666");

        postEnter
          .append("image")
          .attr("x", -7)
          .attr("y", -7)
          .attr("width", 14)
          .attr("height", 14)
          .attr("fill", "white")
          .attr("xlink:href", (d) => d.iconSVG)
          .style("filter", function (d) {
            return "url(#white)";
          })
          .append("svg:title")
          .text(function (d) {
            return d.title;
          });

        postEnter.append("circle").attr("class", "anchor").attr("r", 2).attr("fill", "#666");

        let postMerge = postEnter.merge(postSelection);

        postMerge.attr("transform", function (d) {
          var xPosition = xScale(d.date);

          if (!isNaN(xPosition)) {
            return "translate(" + xPosition + "," + (height - d.height - 18) + ")";
          }
        });

        postMerge.select("line").attr("y2", function (d) {
          return d.height - 4;
        });

        postMerge.select("circle.anchor").attr("cy", function (d) {
          return d.height - 2;
        });
      }
    },
    [icons],
  );

  const zoomBehaviour = useCallback(
    (view, gX, gY, xAxis, yAxis, xScale, yScale, svg, areaGenerator) => {
      const filter = (event) => {
        event.preventDefault();
        return (!event.ctrlKey || event.type === "wheel") && !event.button;
      };

      const zoomed = (event) => {
        const { transform } = event;
        const newXScale = transform.rescaleX(xScale);
        gX.call(xAxis.scale(newXScale));

        // Update the area paths based on the new x-scale
        const newAreaGenerator = area()
          .x((sequence) => newXScale(sequence.data.timestamp))
          .y0((sequence) => yScale(sequence[0]))
          .y1((sequence) => yScale(sequence[1]))
          .curve(curveBasis);
        const stackGenerator = stack().keys(keys);

        createNightRectangles(newXScale, svg);
        const layers = stackGenerator(data);
        svg
          .selectAll(".layer")
          .data(layers)
          .join("path")

          .attr("class", "layer")
          .style("opacity", 0.9)

          .attr("fill", (layer) => {
            return colors[`${layer.key}`];
          })
          .attr("d", newAreaGenerator);

        svg.selectAll(".x-axis line").attr("stroke", "rgba(151,151,151,.3)").attr("fill", "none").attr("y2", -height);
        displayPostIcons(newXScale, svg);
      };

      const reset = () => {
        svg.transition().duration(750).call(zoomIdentity);
      };

      const zoomChart = zoom()
        .scaleExtent([1, 10]) // Adjust zoom scale extent as needed
        .translateExtent([
          [0, 0],
          [width, height],
        ])
        .extent([
          [0, 0],
          [width, height],
        ])
        .filter(filter)
        .on("zoom", zoomed);

      svg.call(zoomChart);

      return Object.assign(svg.node(), { reset });
    },

    [colors, createNightRectangles, data, displayPostIcons, keys],
  );

  useEffect(() => {
    if (data.length === 0) return;
    const svg = select(svgRef.current);

    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    const extent = [0, max(layers, (layer) => max(layer, (sequence) => sequence[1]))];

    const initialAreaGenerator = area()
      .x((sequence) => xScale(sequence.data.timestamp))
      .y0((sequence) => yScale(sequence[0] * 0.3))
      .y1((sequence) => yScale(sequence[1] * 0.3))
      .curve(curveBasis);

    const areaGenerator = area()
      .x((sequence) => xScale(sequence.data.timestamp))
      .y0((sequence) => yScale(sequence[0]))
      .y1((sequence) => yScale(sequence[1]))
      .curve(curveBasis);

    const xScale = scaleTime()
      .domain(d3Extent(data, (d) => new Date(d.timestamp))) // Assumes `data` has `timestamp` field with date strings
      .range([marginLeft, width - marginRight]);

    const yScale = scaleLinear()
      .domain(extent)
      .range([height - marginBottom, 25]);

    createNightRectangles(xScale, svg);

    const view = svg
      .selectAll(".layer")
      .data(layers)
      .join("path")

      .attr("class", "layer")
      .style("opacity", 0.9)

      .attr("fill", (layer) => {
        return colors[`${layer.key}`];
      })
      .attr("d", initialAreaGenerator)
      .transition()
      .attr("d", areaGenerator);

    // const calculateTickPoints = (d, i) => {
    //   if (data.length > 8) {
    //     return i % Math.floor(data.length / 8) === 0;
    //   }
    //   return true;
    // };

    const xAxis = axisBottom(xScale);
    const yAxis = axisLeft(yScale).ticks(5);

    const gX = svg
      .select(".x-axis")
      .style("transform", `translateY(${height - marginBottom}px)`)
      .call(xAxis)
      .call((g) => g.select(".domain").remove())
      .raise();

    const gY = svg
      .select(".y-axis")
      .style("transform", `translateX(${marginLeft}px)`)
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .raise();

    svg.selectAll(".x-axis line").attr("stroke", "rgba(151,151,151,.3)").attr("fill", "none").attr("y2", -height);

    svg.selectAll(".y-axis text").style("text-anchor", "start").style("transform", `translateY(-10px)`);

    svg
      .selectAll(".y-axis line")
      .attr("stroke", "rgba(151,151,151,.3)")
      .attr("transform", "translate(-50,0)")
      .attr("fill", "none")
      .attr("x2", width);

    displayPostIcons(xScale, svg);

    setForceRerender(1);

    zoomBehaviour(view, gX, gY, xAxis, yAxis, xScale, yScale, svg, areaGenerator);

    // prevent scrolling then apply the default filter
  }, [
    data,
    colors,
    eveningHours.length,
    eveningHours.start,
    keys,
    icons,
    createNightRectangles,
    displayPostIcons,
    zoomBehaviour,
  ]);

  return (
    <>
      <svg ref={svgRef} width={`${width}px`} height={`${height}px`}>
        <g className="x-axis" />
        <g className="y-axis" />
      </svg>
    </>
  );
};

export default StackedAreaGraph;
