import React, { useCallback, useState } from "react";

import "./Pagination.scss";

type PaginationButtonProps = {
  changePage: Function;
  pageNumber: number;
  currentSelected: number;
};

const PaginationButton = ({ changePage, pageNumber, currentSelected }: PaginationButtonProps) => {
  return (
    <div
      className={`pagination-element ${currentSelected === pageNumber ? "active" : ""}`}
      onClick={() => {
        changePage(pageNumber);
      }}
    >
      {pageNumber}
    </div>
  );
};

type PaginationProps = {
  totalPages: number;
  selectedPage: number;
  setSelectedPage: Function;
};

const Pagination = ({ totalPages, selectedPage, setSelectedPage }: PaginationProps) => {
  //   const [selected, setSelected] = useState(1);

  const changePage = useCallback(
    (pageNumber: number) => {
      if (pageNumber >= 1 && pageNumber <= totalPages) {
        setSelectedPage(pageNumber);
      }
    },
    [totalPages],
  );

  const renderStartPaginationSection = useCallback(() => {
    const paginationNumbers = [2, 3, 4, 5];
    return (
      <>
        {paginationNumbers.map((number) => (
          <PaginationButton key={number} changePage={changePage} pageNumber={number} currentSelected={selectedPage} />
        ))}
        <div className="pagination-element">...</div>
      </>
    );
  }, [selectedPage, changePage]);

  const renderMiddlePaginationSection = useCallback(() => {
    const paginationNumbers = [selectedPage - 1, selectedPage, selectedPage + 1];
    return (
      <>
        <div className="pagination-element">...</div>
        {paginationNumbers.map((number) => (
          <PaginationButton changePage={changePage} pageNumber={number} currentSelected={selectedPage} />
        ))}
        <div className="pagination-element">...</div>
      </>
    );
  }, [selectedPage, changePage]);

  const renderEndPaginationSection = useCallback(() => {
    const paginationNumbers = [totalPages - 4, totalPages - 3, totalPages - 2, totalPages - 1];
    return (
      <>
        <div className="pagination-element">...</div>
        {paginationNumbers.map((number) => (
          <PaginationButton changePage={changePage} pageNumber={number} currentSelected={selectedPage} />
        ))}
      </>
    );
  }, [totalPages, selectedPage, changePage]);

  if (totalPages < 8) {
    return (
      <div className="pagination-container">
        <div
          className="pagination-element"
          onClick={() => {
            changePage(selectedPage - 1);
          }}
        >
          {" "}
          &#11164;{" "}
        </div>
        {[1, 2, 3, 4, 5, 6, 7].slice(0, totalPages).map((number, i) => {
          return <PaginationButton changePage={changePage} pageNumber={number} currentSelected={selectedPage} key={i} />;
        })}
        <div
          className="pagination-element"
          onClick={() => {
            changePage(selectedPage + 1);
          }}
        >
          {" "}
          &#10148;{" "}
        </div>
      </div>
    );
  }

  const displayedPointers = () => {
    if (selectedPage <= 4) {
      return renderStartPaginationSection();
    } else if (selectedPage <= totalPages - 4) {
      return renderMiddlePaginationSection();
    } else {
      return renderEndPaginationSection();
    }
  };

  return (
    <div className="pagination-container">
      <div
        className="pagination-element"
        onClick={() => {
          changePage(selectedPage - 1);
        }}
      >
        {" "}
        &#11164;{" "}
      </div>
      <PaginationButton changePage={changePage} pageNumber={1} currentSelected={selectedPage} />
      {displayedPointers()}
      <PaginationButton changePage={changePage} pageNumber={totalPages} currentSelected={selectedPage} />
      <div
        className="pagination-element"
        onClick={() => {
          changePage(selectedPage + 1);
        }}
      >
        {" "}
        &#10148;{" "}
      </div>
    </div>
  );
};

export default Pagination;
