import React from "react";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";

type DomainStatusSettingsProps = {
  showDomainStatusSettings: boolean;
  handleToggleDomainStatus: Function;
};

const DomainStatusSettings = ({ handleToggleDomainStatus, showDomainStatusSettings }: DomainStatusSettingsProps) => {
  return (
    <div>
      <div
        className="brand-settings-title first"
        onClick={() => {
          handleToggleDomainStatus();
        }}
      >
        Domain status
      </div>
      <div className={["brand-settings-container", showDomainStatusSettings && "active"].join(" ")}>
        <div className="domain-status-wrapper">
          <p>Standard</p>
          <div className="domain-status-container">
            <div className="domain-status-item">
              <div className="domain-status-item-title">Staging</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
            <div className="domain-status-item">
              <div className="domain-status-item-title">Production</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
          </div>
        </div>

        <div className="domain-status-wrapper">
          <p>Waves</p>
          <div className="domain-status-container">
            <div className="domain-status-item">
              <div className="domain-status-item-title">Staging</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
            <div className="domain-status-item">
              <div className="domain-status-item-title">Production</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
          </div>
        </div>

        <div className="domain-status-wrapper">
          <p>Smartify</p>
          <div className="domain-status-container">
            <div className="domain-status-item">
              <div className="domain-status-item-title">Staging</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
            <div className="domain-status-item">
              <div className="domain-status-item-title">Production</div>
              <div>
                <ToggleSwitch />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomainStatusSettings;
