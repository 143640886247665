import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import { fetchImportTypes, fetchImports } from "../../../store/slices/imports";
import Button from "../../../components/ui/common/Button";
import { EditIcon, DeleteIcon } from "../../../icons/Icons";
import { CircleLoader } from "../../../icons/Loaders";
import Modal from "../../../components/ui/modal/Modal";
import EditImportModal from "../../../components/modals/EditImportModal/EditImportModal";
import { PlusIcon } from "../../../icons/Icons";
import "../Admin.scss";

const Imports = () => {
  const dispatch = useAppDispatch();

  const imports = useSelector((state: any) => state.imports.data);
  const isLoading = useSelector((state: any) => state.imports.isLoading);
  const currentBrand = useSelector((state: any) => state.currentBrand.data);
  const [editingImport, setEditingImport] = useState(false);
  const [importEditData, setImportEditData] = useState({});
  const [creatingNewImport, setCreatingNewImport] = useState(false);

  useEffect(() => {
    document.title = "Admin - Imports - Smartocto real-time";
  }, []);

  const handleDelete = (id) => {};

  const columns = [
    { title: "id", label: "id" },
    { title: "insertMethod", label: "Insert Method" },
    { title: "index", label: "Index" },
    { title: "active", label: "Active" },
    { title: "type", label: "Type" },
    { title: "infoType", label: "Info Type" },
    { title: "url", label: "URL" },
    { title: "brandId", label: "brandId" },
    { title: "actions", label: "Actions" },
  ];

  const dataWithActions = imports.map((e, i) => {
    return {
      ...e,
      insertMethod: e.insertMethod || "",
      index: e.index || "",
      active: e.active ? "yes" : "no",
      infoType: e.infoType || "",
      url: e.url || "",
      sort: {id: e.id, insertMethod: e?.insertMethod, index: e?.index, active: e?.active, type: e?.type, infoType: e?.infoType, url: e?.url, brandId: e?.brandId},
      actions: (
        <div style={{ display: "flex" }}>
          <Button
            variant="warning"
            data-testid={`editButton${i}`}
            size="small"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              setImportEditData(e);
              setEditingImport(true);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            variant="danger"
            data-testid={`deleteButton${i}`}
            size="small"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
  });

  useEffect(() => {
    dispatch(fetchImports());
    dispatch(fetchImportTypes());
  }, [dispatch]);

  const handleSetNewImportCreation = () => {
    setImportEditData({
      brandId: currentBrand.domain,
      active: true,
    });
    setCreatingNewImport(true);
    setEditingImport(true);
  };

  const handleModalClose = () => {
    setCreatingNewImport(false);
    setEditingImport(false);
  };

  return (
    <>
      <Modal isOpen={editingImport} onClose={handleModalClose}>
        <EditImportModal data={importEditData} newImport={creatingNewImport} handleClose={handleModalClose} />
      </Modal>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Imports </h1>

        <div className="admin-page-header-btns">
          <Button
            style={{ marginLeft: 20, padding: "6px 8px" }}
            variant="success"
            onClick={() => {
              handleSetNewImportCreation();
            }}
            disabled
          >
            <PlusIcon width="16px" height="16px" />
          </Button>
        </div>

        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Imports;
