import React, { useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import "./MainNavigation.scss";
import { useSelector } from "react-redux";
import DevHelperTools from "../devHelperTools/DevHelperTools";
import useIsFeatureAllowed from "../../customHooks/useIsFeatureAllowed";
import useCheckUserPermissions from "../../customHooks/useCheckUserPermissions";
import useGetEnvironment from "../../customHooks/useGetEnvironment";
import {
  LogoIcon,
  StreamIcon,
  StoriesIcon,
  VideosIcon,
  BundleIcon,
  InkedIcon,
  CurrentIcon,
  LogbookIcon,
  InsightsIcon,
  AdminIcon,
  SettingsIcon,
} from "../../icons/Icons";

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
    } | null;
  };
};

const MainNavigation = () => {
  const location = useLocation();
  const isFeatureAllowed = useIsFeatureAllowed();
  const checkUserPermissions = useCheckUserPermissions();
  const { pathname } = location;
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);
  const user = useSelector((state: any) => state.currentUser.data) || {};
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);
  const getEnvironment = useGetEnvironment();

  const navIconProps = {
    width: "15",
    height: "15",
  };

  const shouldShowDevTools = useMemo(() => {
    const environment = getEnvironment();
    return environment.includes("staging") || environment === "localhost";
  }, [getEnvironment]);

  return (
    <div className={`navigation-wrapper ${isMobileNavigationShown ? "mobile-open" : "mobile-closed"}`}>
      <div className="navigation-container">
        <div className="logo">
          <Link className="nav-link" to={"/"}>
            <LogoIcon width="60px" />
          </Link>
        </div>
        <nav className="navigation">
          <Link className={`nav-link ${pathname.includes("/stream") ? "active" : null}`} to={"/stream"}>
            <StreamIcon {...navIconProps} />
            <span className="nav-link-text">Stream</span>
          </Link>
          {isFeatureAllowed("stories") && (
            <Link className={`nav-link ${pathname.includes("/stories") ? "active" : null}`} to={"/stories"}>
              <StoriesIcon {...navIconProps} />
              <span className="nav-link-text">Stories</span>
            </Link>
          )}
          {isFeatureAllowed("videos") && (
            <Link className={`nav-link ${pathname.includes("/videos") ? "active" : null}`} to={"/videos"}>
              <VideosIcon {...navIconProps} />
              <span className="nav-link-text">Videos</span>
            </Link>
          )}
          {isFeatureAllowed("landingPages") && (
            <Link className={`nav-link ${pathname.includes("/pages") ? "active" : null}`} to={"/pages"}>
              <StoriesIcon {...navIconProps} />
              <span className="nav-link-text">Pages</span>
            </Link>
          )}
          {isFeatureAllowed("bundles") && (
            <Link className={`nav-link ${pathname.includes("/bundles") ? "active" : null}`} to={"/bundles"}>
              <BundleIcon width="15" height="17" />
              <span className="nav-link-text">Bundles</span>
            </Link>
          )}
          <Link className={`nav-link ${pathname.includes("/inked") ? "active" : null}`} to={"/inked"}>
            <InkedIcon {...navIconProps} />
            <span className="nav-link-text">Inked</span>
          </Link>
          <Link className="nav-link" to={"/reports/current/ob"} target="_blank">
            <CurrentIcon {...navIconProps} />
            <span className="nav-link-text">Current</span>
          </Link>
          <Link className={`nav-link ${pathname.includes("/logbook") ? "active" : null}`} to={"/logbook"}>
            <LogbookIcon {...navIconProps} />
            <span className="nav-link-text">Logbook</span>
          </Link>
          {isFeatureAllowed("insights") && (
            <a
              className="nav-link"
              target="_blank"
              href={`https://api.smartocto.com/api/session-transfer/historical?brand=${brandInfo?.domain}`}
            >
              <InsightsIcon {...navIconProps} />
              <span className="nav-link-text">Insights</span>
            </a>
          )}
          {(checkUserPermissions("admin") || checkUserPermissions("superuser")) && (
            <Link className={`nav-link admin ${pathname.includes("/admin") ? "active" : null}`} to={"/admin"}>
              <AdminIcon {...navIconProps} />
              <span className="nav-link-text">Admin</span>
            </Link>
          )}
        </nav>
        <div className="user-options">
          {shouldShowDevTools && <DevHelperTools />}
          <Link className="user-settings" to={"/settings"}>
            <SettingsIcon />
          </Link>
          <div className="user-avatar">
            <img
              style={{ color: "white" }}
              src={
                user.imageUrl ||
                "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQpg33BcIxjKDqG3k98UYSAjdX0cxFfcCslkvkmme5hC8xwJ9Xf8pA7iuJnUtJpHF8rui4&usqp=CAU"
              }
              alt="profile-avatar"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNavigation;
