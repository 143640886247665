import ApiCore from "./ApiCore";

/**
 * All API End-points
 */
class Api {
  api: ApiCore;
  constructor(baseUrl = process.env.REACT_APP_URL) {
    this.api = new ApiCore();
    if (baseUrl) {
      const apiHost = window.localStorage.getItem("apihost");
      if (apiHost) {
        this.api.setBaseUrl(`https://${apiHost}`);
      } else {
        this.api.setBaseUrl(baseUrl);
      }
    }
  }

  // GET METHODS

  /**
   *
   */
  getUsers() {
    return this.api.get(`/api/admin/users`, {
      brand: this.api.getBrandId(),
    });
  }

  getAllUsers() {
    return this.api.get(`/api/admin/users`);
  }

  getCurrentBrand(authToken?: string) {
    if (authToken) {
      return this.api.get(`/api/auth/check/${this.api.getBrandId()}?auth=${authToken}`);
    }
    return this.api.get(`/api/auth/check/${this.api.getBrandId()}`);
  }

  getNotifiers() {
    return this.api.get(`/api/admin/brands/${this.api.getBrandId()}/notifier`);
  }

  getNotifierTypes() {
    return this.api.get(`/api/admin/notifierTypes`);
  }

  getImports() {
    return this.api.get(`/api/admin/brands/${this.api.getBrandId()}/import/`);
  }

  getImportTypes() {
    return this.api.get(`api/admin/importTypes`);
  }

  getBrands() {
    return this.api.get(`/api/admin/brands`);
  }

  getAllBrands() {
    return this.api.get(`/api/admin/brands/getAll`);
  }

  getFeedStreamItems(sections: string[] = []) {
    const params: any = {};
    if (sections.length) {
      params.sections = sections.join(",");
    }
    return this.api.get(`/api/brands/${this.api.getBrandId()}/notifications/`, params);
  }

  getFeedStoriesItems(filter = "page", searchTerm = "", sections: string[] = []) {
    const params: any = {
      filter,
    };

    if (searchTerm) {
      params.q = searchTerm;
    }

    if (sections.length) {
      params.sections = sections.join(",");
    }

    return this.api.get(`/api/brands/${this.api.getBrandId()}/stories/`, params);
  }

  getFeedVideosItems(filter = "page", searchTerm = "") {
    const params: any = {
      filter,
    };

    if (searchTerm) {
      params.q = searchTerm;
    }

    return this.api.get(`/api/brands/${this.api.getBrandId()}/videos/`, params);
  }

  getFeedPagesItems(filter = "page", searchTerm = "") {
    const params: any = {
      filter,
    };

    if (searchTerm) {
      params.q = searchTerm;
    }

    return this.api.get(`/api/brands/${this.api.getBrandId()}/landingPages`, params);
  }

  getFeedInkedItems() {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/notifications/`, {
      filter: "me",
    });
  }

  getFeedBundlesItems() {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/bundles/`);
  }

  getBundleGraphData(id: string, type: string) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/bundles/${id}/cvi/${type}`);
  }

  getBundle(id: string) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/bundles/${id}`);
  }

  getStoriesSparkData(id: string) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/stories/${id}/sparkData/`);
  }

  getVideoSparkData(id: string) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/videos/${id}/sparkdata/`);
  }

  getBundlesSparkData(id: string) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/bundles/${id}/sparkdata/`);
  }

  getActiveFeedItem(id: string, params: object, signal: any) {
    return this.api.get(
      `/api/brands/${this.api.getBrandId()}/stories/${id}/`,
      {
        ...params,
      },
      signal,
    );
  }

  getActiveFeedItemCVI(id: string, type: string = "stories", signal: any) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/${type}/${id}/cvi`, {}, signal);
  }

  getActiveFeedItemSegmentCVI(id: string, segment_id: string, signal: any) {
    return this.api.get(`/api/brands/${this.api.getBrandId()}/stories/${id}/segmentCvi/${segment_id}`, {}, signal);
  }

  getActiveFeedItemNumbers(id: string, params: object) {
    return this.api.get(`api/brands/${this.api.getBrandId()}/stories/${id}/numbers`, {
      ...params,
    });
  }

  getSegments() {
    return this.api.get(`api/brands/${this.api.getBrandId()}/segments/`);
  }

  getSections(type = "feed") {
    return this.api.get(
      `api/brands/section/list?brandId=${this.api.getBrandId()}&brands=${this.api.getBrandId()}&type=${type}`,
    );
  }

  getImpact(from, to, channel) {
    return this.api.get(`api/brands/${this.api.getBrandId()}/impact/?from=${from}&to=${to}&channel=${channel}`);
  }

  getTestArchive() {
    return this.api.get(`api/brands/${this.api.getBrandId()}/headertest_archive`);
  }

  getMultibrand() {
    return this.api.get(`api/brands/${this.api.getBrandId()}/dashboardConfig/`);
  }

  getCurrentUser() {
    return this.api.get(`api/user/`);
  }

  getAlgorithms() {
    return this.api.get(`api/brands/${this.api.getBrandId()}/algorithms/`);
  }

  getCredits() {
    return this.api.get(`api/admin/brands/credits/list`);
  }

  getAICredits() {
    return this.api.get(`api/ai/${this.api.getBrandId()}/credit_list/`);
  }

  getUmbrellaBrands() {
    return this.api.get(`api/admin/umbrella-brands/list`);
  }

  getNotificationsStatistics(period = "week") {
    return this.api.get(`api/brands/${this.api.getBrandId()}/notifications/statistics?period=${period}`);
  }

  getRoiOwnPlatformStatistics(period = "week") {
    return this.api.get(`api/brands/${this.api.getBrandId()}/smartify/roi/own_platform?period=${period}`);
  }

  getRoiSocialStatistics(period = "week") {
    return this.api.get(`api/brands/${this.api.getBrandId()}/smartify/roi/social?period=${period}`);
  }

  getRoiConversionsStatistics(period = "week") {
    return this.api.get(`api/brands/${this.api.getBrandId()}/smartify/roi/conversions?period=${period}`);
  }

  getHeaderTestsStatistics(period = "week") {
    return this.api.get(`api/brands/${this.api.getBrandId()}/headertests/statistics?period=${period}`);
  }

  getSwitchMailReports(state) {
    return this.api.get(`api/admin/brands/${this.api.getBrandId()}/mailReports/${state}`);
  }

  getSendMailReports() {
    return this.api.get(`api/admin/brands/${this.api.getBrandId()}/sendMailReports`);
  }

  getDeleteTempListeners() {
    return this.api.get(`api/admin/brands/${this.api.getBrandId()}/deleteTempListeners`);
  }

  // POST METHODS
  login(payload) {
    return this.api.post(`api/auth/login`, payload);
  }

  checkToken(payload) {
    return this.api.post(`api/auth/check_token`, payload);
  }

  setPassword(payload) {
    return this.api.post(`api/auth/set_password`, payload);
  }

  requestInvite(userId, payload) {
    return this.api.post(`api/auth/invite/${userId}`, payload);
  }

  forgotPassword(userId) {
    return this.api.post(`api/auth/forgot`, { userid: userId, brandId: this.api.getBrandId() });
  }

  postNotificationAsInked(id, action) {
    return this.api.post(`api/brands/${this.api.getBrandId()}/notifications/${id}/ink/`, {
      action: action,
    });
  }

  postItemToBundle(postId, payload) {
    return this.api.post(`api/brands/${this.api.getBrandId()}/stories/${postId}/bundle/`, {
      ...payload,
    });
  }

  postTestDisabled(id: string) {
    return this.api.post(`api/brands/${this.api.getBrandId()}/headertest/toggleDisabled`, {
      id: id,
    });
  }

  // Smartocto AI

  getRPOAIheadliner(client, request_id, text) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/headliner`, {
      client,
      request_id,
      text,
    });
  }

  getSOAIheadliner(client, request_id, text, type) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/headliner`, {
      client,
      request_id,
      text,
      type,
    });
  }

  getPublishTimer(client, hasImages, hasVideos, optimize, request_id, sort, text, timeFrom, timeTo) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/publish_timer`, {
      request_id,
      client,
      text,
      hasImages,
      hasVideos,
      sort,
      optimize,
      timeFrom,
      timeTo,
    });
  }

  getParagraphRephraser(request_id, client, text, type) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/rephraser`, {
      request_id,
      client,
      text,
      type,
    });
  }

  getProduceCategories(request_id, text) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/categorizer`, {
      request_id,
      text,
    });
  }

  getArticleRewrite(request_id, text, type) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/rewriter`, {
      request_id,
      text,
      type,
    });
  }

  getArticleReadability(request_id, text) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/readability`, {
      request_id,
      text,
    });
  }

  getSentiment(request_id, client, text) {
    return this.api.post(`api/ai/${this.api.getBrandId()}/sentiment`, {
      request_id,
      text,
      client,
    });
  }

  postCreateComment(postId: string, text: string) {
    return this.api.post(`api/brands/${this.api.getBrandId()}/stories/${postId}/add_comment`, {
      edit_comment: text,
    });
  }

  postAddUser(user) {
    return this.api.post(`api/admin/users`, {
      ...user,
    });
  }

  postUpdateUser(email, user) {
    return this.api.post(`api/admin/users/${email}`, {
      ...user,
    });
  }

  postUpdateCurrentUser(userData) {
    return this.api.post(`api/user/`, {
      ...userData,
    });
  }

  postInviteUser(email, host, generatePassword) {
    return this.api.post(`api/admin/users/${email}/invite`, {
      host: host,
      generatePassword: !!generatePassword,
    });
  }

  postUpdateProfile(profile) {
    return this.api.post(`/api/user`, profile);
  }

  postUpdateNotifier(notifierId, payload) {
    return this.api.post(`/api/admin/brands/${this.api.getBrandId()}/notifier/${notifierId}`, {
      ...payload,
    });
  }

  postCreateNotifier(brandId, payload: any) {
    return this.api.post(`/api/admin/brands/${brandId}/notifier/`, {
      ...payload,
    });
  }

  postUpdateImport(importId, brandId, payload) {
    return this.api.post(`/api/admin/brands/${brandId}/import/${importId}`, {
      ...payload,
    });
  }

  postCreateImport(brandId, payload) {
    return this.api.post(`/api/admin/brands/${brandId}/import/`, {
      ...payload,
    });
  }

  postUpdateBrand(brandId: string, payload: any) {
    return this.api.post(`/api/admin/brands/${brandId}`, {
      ...payload,
    });
  }

  postUpdateUmbrellaBrand(brandId: string, payload: any) {
    return this.api.post(`/api/admin/umbella-brands/${brandId}`, {
      ...payload,
    });
  }

  // DELETE METHODS
  deleteStoryFromBundle(bundleId, postId) {
    return this.api.delete(`api/brands/${this.api.getBrandId()}/stories/${postId}/bundle/${bundleId}`);
  }

  deleteBundle(bundleId) {
    return this.api.delete(`api/brands/${this.api.getBrandId()}/bundles/${bundleId}`);
  }

  deleteUser(id) {
    return this.api.delete(`api/admin/users/${id}`);
  }

  deleteNotifier(notifierId) {
    return this.api.delete(`api/admin/brands/${this.api.getBrandId()}/notifier/${notifierId}`);
  }

  deleteImport(importId: string, brandId: string) {
    return this.api.delete(`/api/admin/brands/${brandId}/import/${importId})`);
  }

  deleteUmbrellaBrand(brandId) {
    return this.api.delete(`/api/admin/umbrella-brands/${brandId}`);
  }

  logout() {
    return this.api.get(`/api/auth/logout`);
  }
} // end class

export default Api;
