import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";
// import feed from "./feed";

type State = {
  isLoading: boolean;
  error: Object | null;
  feedItem: any | null;

  isLoadingCVI: boolean;
  errorCVI: Object | null;
  cviData: Object | null;

  isLoadingNumbers: boolean;
  errorNumbers: Object | null;
  numbersData: Object | null;

  isLoadingSegments: boolean;
  errorSegments: Object | null;
  segmentsData: any | null;
  selectedSegment: Object;
};

const INITIAL_STATE = {
  isLoading: true,
  error: null,
  feedItem: {},

  isLoadingCVI: true,
  cviData: {},
  errorCVI: null,

  isLoadingNumbers: true,
  errorNumbers: null,
  numbersData: {},

  isLoadingSegments: true,
  errorSegments: null,
  segmentsData: {},
  selectedSegment: { key: "", label: "" },
};

const activeFeedItem = createSlice({
  name: "activeFeedItem",
  initialState: INITIAL_STATE,
  reducers: {
    fetchActiveFeedItemStart: (state: State) => {
      state.isLoading = true;
    },
    fetchActiveFeedItemSuccess: (state: State, action) => {
      state.isLoading = false;
      state.feedItem = action.payload;
    },
    fetchActiveFeedItemFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchActiveFeedItemCVIStart: (state: State) => {
      state.isLoadingCVI = true;
    },
    fetchActiveFeedItemCVISuccess: (state: State, action) => {
      state.isLoadingCVI = false;
      state.cviData = action.payload;
    },
    fetchActiveFeedItemCVIFailed: (state: State, action) => {
      state.isLoadingCVI = false;
      state.errorCVI = action.payload;
    },

    fetchActiveFeedItemSegmentCVIStart: (state: State) => {
      state.isLoadingCVI = true;
    },
    fetchActiveFeedItemSegmentCVISuccess: (state: State, action) => {
      state.isLoadingCVI = false;
      state.cviData = action.payload;
    },
    fetchActiveFeedItemSegmentCVIFailed: (state: State, action) => {
      state.isLoadingCVI = false;
      state.errorCVI = action.payload;
    },

    fetchActiveFeedItemNumbersStart: (state: State) => {
      state.isLoadingNumbers = true;
    },
    fetchActiveFeedItemNumbersSuccess: (state: State, action) => {
      state.isLoadingNumbers = false;
      state.numbersData = action.payload;
    },
    fetchActiveFeedItemNumbersFailed: (state: State, action) => {
      state.isLoadingNumbers = false;
      state.errorNumbers = action.payload;
      state.numbersData = {};
    },

    updateTimelineItemsSuccess: (state: State, action) => {
      if (state.feedItem !== null) {
        const timelineItems = state.feedItem?.post?.timeline;

        if (timelineItems) {
          state.feedItem.post.timeline = timelineItems.map((timelineItem) => {
            if (timelineItem.item_id === action.payload.item_id) {
              return {
                ...timelineItem,
                inked: action.payload.inked,
                inks: action.payload.inks,
              };
            }

            return timelineItem;
          });
        }
      }
    },

    addTimelineItemSuccess: (state: State, action) => {
      if (state.feedItem !== null) {
        const timelineItems = state.feedItem?.post?.timeline;

        if (timelineItems) {
          state.feedItem.post.timeline.unshift(action.payload);
        }
      }
    },

    fetchSegmentsStart: (state: State) => {
      state.isLoadingSegments = true;
    },
    fetchSegmentsSuccess: (state: State, action) => {
      state.isLoadingSegments = false;
      state.segmentsData = action.payload;
    },
    fetchSegmentsFailed: (state: State, action) => {
      state.isLoadingSegments = false;
      state.errorSegments = action.payload;
    },

    changeSelectedSegment: (state: State, action) => {
      state.selectedSegment = action.payload;
    },
  },
});

export default activeFeedItem.reducer;

const {
  fetchActiveFeedItemStart,
  fetchActiveFeedItemSuccess,
  fetchActiveFeedItemFailed,
  fetchActiveFeedItemCVIStart,
  fetchActiveFeedItemCVISuccess,
  fetchActiveFeedItemCVIFailed,
  fetchActiveFeedItemSegmentCVIStart,
  fetchActiveFeedItemSegmentCVISuccess,
  fetchActiveFeedItemSegmentCVIFailed,
  fetchActiveFeedItemNumbersStart,
  fetchActiveFeedItemNumbersSuccess,
  fetchActiveFeedItemNumbersFailed,
  updateTimelineItemsSuccess,
  addTimelineItemSuccess,
  fetchSegmentsStart,
  fetchSegmentsSuccess,
  fetchSegmentsFailed,
  changeSelectedSegment,
} = activeFeedItem.actions;

let currentfetchActiveFeedItemRequest = null;
let currentfetchActiveFeedItemCVIRequest = null;
let currentfetchActiveFeedItemNumbersRequest = null;

export const fetchActiveFeedItem = (id: string, params: object) => async (dispatch: Function) => {
  try {
    if (currentfetchActiveFeedItemRequest) {
      // @ts-ignore
      currentfetchActiveFeedItemRequest.abort();
    }
    dispatch(fetchActiveFeedItemStart());

    const controller = new AbortController(); // Create a new AbortController
    // @ts-ignore
    currentfetchActiveFeedItemRequest = controller;

    const api = new Api();
    const result = await api.getActiveFeedItem(id, params, controller.signal);
    dispatch(fetchActiveFeedItemSuccess(result));
  } catch (err) {
    dispatch(fetchActiveFeedItemFailed(err));
  }
};

export const fetchActiveFeedItemCVI =
  (id: string, type: string = "stories") =>
  async (dispatch: Function) => {
    try {
      if (currentfetchActiveFeedItemCVIRequest) {
        // @ts-ignore
        currentfetchActiveFeedItemCVIRequest.abort();
      }
      dispatch(fetchActiveFeedItemCVIStart());
      const controller = new AbortController(); // Create a new AbortController
      // @ts-ignore
      currentfetchActiveFeedItemCVIRequest = controller;

      const api = new Api();
      const result = await api.getActiveFeedItemCVI(id, type, controller.signal);
      dispatch(fetchActiveFeedItemCVISuccess(result));
    } catch (err) {
      dispatch(fetchActiveFeedItemCVIFailed(err));
    }
  };

export const fetchActiveFeedItemSegmentCVI = (id: string, segment_id: string) => async (dispatch: Function) => {
  try {
    if (currentfetchActiveFeedItemCVIRequest) {
      // @ts-ignore
      currentfetchActiveFeedItemCVIRequest.abort();
    }
    dispatch(fetchActiveFeedItemSegmentCVIStart());
    const controller = new AbortController(); // Create a new AbortController
    // @ts-ignore
    currentfetchActiveFeedItemCVIRequest = controller;

    const api = new Api();
    const result = await api.getActiveFeedItemSegmentCVI(id, segment_id, controller.signal);
    dispatch(fetchActiveFeedItemSegmentCVISuccess(result));
  } catch (err) {
    dispatch(fetchActiveFeedItemSegmentCVIFailed(err));
  }
};

export const fetchActiveFeedItemNumbers =
  (id: string, params: Object = {}) =>
  async (dispatch: Function) => {
    try {
      if (currentfetchActiveFeedItemNumbersRequest) {
        // @ts-ignore
        currentfetchActiveFeedItemNumbersRequest.abort();
      }
      dispatch(fetchActiveFeedItemNumbersStart());
      const controller = new AbortController(); // Create a new AbortController
      // @ts-ignore
      currentfetchActiveFeedItemNumbersRequest = controller;

      const api = new Api();
      const result = await api.getActiveFeedItemNumbers(id, params);
      dispatch(fetchActiveFeedItemNumbersSuccess(result));
    } catch (err) {
      dispatch(fetchActiveFeedItemNumbersFailed(err));
    }
  };

export const fetchSegments = () => async (dispatch: Function) => {
  try {
    dispatch(fetchSegmentsStart());
    const api = new Api();
    const result = await api.getSegments();
    dispatch(fetchSegmentsSuccess(result));
  } catch (err) {
    dispatch(fetchSegmentsFailed(err));
  }
};

export const updateTimelineItems = (newTimelineItems) => async (dispatch: Function) => {
  dispatch(updateTimelineItemsSuccess(newTimelineItems));
};

export const addTimelineItem = (newTimelineItem) => async (dispatch: Function) => {
  dispatch(addTimelineItemSuccess(newTimelineItem));
};

export const setSelectedSegment = (segment) => async (dispatch: Function) => {
  dispatch(changeSelectedSegment(segment));
};
