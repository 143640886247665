export const mainGraphData = 

// {
//     "name": "Conversion Score",
//     "channels": [
//         {
//             "data": [
//                 [
//                     1704357000000,
//                     0
//                 ],
//                 [
//                     1704358800000,
//                     2
//                 ],
//                 [
//                     1704360600000,
//                     0
//                 ],
//                 [
//                     1704362400000,
//                     4
//                 ]
//             ],
//             "_listeners": [],
//             "id": "pageview",
//             "color": "#800000",
//             "name": "pageview",
//             "total": 0
//         }
//     ],
//     "startDate": "January 4",
//     "endDate": "January 4",
//     "bars": [
//         {
//             "color": "#800000",
//             "name": "pageview",
//             "value": 0,
//             "displayedValue": 0,
//             "$$hashKey": "object:491"
//         }
//     ],
//     "index": 0,
//     "factor": 100,
//     "performance": 3,
//     "expectation": 1000,
//     "value": 3
// }



{
    "name": "Reach",
    "index": 0,
    "value": 116,
    "factor": 1000,
    "expectation": 10000,
    "story_expectation": 1792,
    "story_expectation_index": 2,
    "channels": [
        {
            "data": [
                [
                    1698136800000,
                    0
                ],
                [
                    1698137100000,
                    0
                ],
                [
                    1698137400000,
                    0
                ],
                [
                    1698137700000,
                    0
                ],
                [
                    1698138000000,
                    12
                ]
            ],
            "_listeners": [],
            "id": "search",
            "color": "#1d28f9",
            "name": "Search",
            "total": 0
        },
        {
            "data": [
                [
                    1698136800000,
                    0
                ],
                [
                    1698137100000,
                    0
                ],
                [
                    1698137400000,
                    72
                ],
                [
                    1698137700000,
                    24
                ],
                [
                    1698138000000,
                    0
                ]
            ],
            "_listeners": [],
            "id": "twitter",
            "color": "#6A13FE",
            "name": "Twitter",
            "total": 0
        },
        {
            "data": [
                [
                    1698136800000,
                    0
                ],
                [
                    1698137100000,
                    24
                ],
                [
                    1698137400000,
                    276
                ],
                [
                    1698137700000,
                    204
                ],
                [
                    1698138000000,
                    108
                ]
            ],
            "_listeners": [],
            "id": "internal",
            "color": "#FFB528",
            "name": "Internal - direct",
            "total": 1
        },
        {
            "data": [
                [
                    1698136800000,
                    0
                ],
                [
                    1698137100000,
                    0
                ],
                [
                    1698137400000,
                    12
                ],
                [
                    1698137700000,
                    12
                ],
                [
                    1698138000000,
                    12
                ]
            ],
            "_listeners": [],
            "id": "direct",
            "color": "#FFB528",
            "name": "Direct: web",
            "total": 0
        },
        {
            "data": [
                [
                    1698136800000,
                    0
                ],
                [
                    1698137100000,
                    0
                ],
                [
                    1698137400000,
                    168
                ],
                [
                    1698137700000,
                    252
                ],
                [
                    1698138000000,
                    204
                ]
            ],
            "_listeners": [],
            "id": "mobileapp",
            "color": "#FFCD28",
            "name": "Direct: app",
            "total": 1
        }
    ],
    "bars": [
        {
            "color": "#1d28f9",
            "name": "Search",
            "value": 0,
            "displayedValue": 0,
            "$$hashKey": "object:322"
        },
        {
            "color": "#6A13FE",
            "name": "Twitter",
            "value": 0,
            "displayedValue": 0,
            "$$hashKey": "object:323"
        },
        {
            "color": "#FFB528",
            "name": "Internal - direct",
            "value": 1,
            "displayedValue": 1,
            "$$hashKey": "object:324"
        },
        {
            "color": "#FFB528",
            "name": "Direct: web",
            "value": 0,
            "displayedValue": 0,
            "$$hashKey": "object:325"
        },
        {
            "color": "#FFCD28",
            "name": "Direct: app",
            "value": 1,
            "displayedValue": 1,
            "$$hashKey": "object:330"
        }
    ]
}