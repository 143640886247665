import React, { useEffect, useState } from "react";
import Button from "../../components/ui/common/Button";
import "./EditDisplayedColumnsModal.scss";

const EditDisplayedColumnsModal = ({ tableTitle, columns }: { tableTitle: string; columns: any }) => {
  const [columnsState, setColumnsState] = useState(columns);

  useEffect(() => {
    if (!localStorage.tablesConfig) {
      localStorage.tablesConfig = JSON.stringify({});
      setColumnsState(
        columns.map((e) => {
          return { ...e, checked: true };
        }),
      );
    }

    if (
      !JSON.parse(localStorage.tablesConfig)[tableTitle] ||
      JSON.parse(localStorage.tablesConfig)[tableTitle]?.length == 0
    ) {
      const tablesConfig = JSON.parse(localStorage.tablesConfig);
      localStorage.tablesConfig = JSON.stringify({ ...tablesConfig, [tableTitle]: [] });
      setColumnsState(
        columns.map((e) => {
          return { ...e, checked: true };
        }),
      );
    } else {
      setColumnsState(
        columns.map((column) => {
          return {
            ...column,
            checked: JSON.parse(localStorage.tablesConfig)[tableTitle]?.find((e) => e === column.label) ? false : true,
          };
        }),
      );
    }
  }, []);

  const handleSave = () => {
    window.location.reload();
  };

  return (
    <div className="edit-displayed-columns-modal">
      <h4>Displayed columns</h4>
      {columnsState.map((column) => {
        return (
          <div className="item" key={column.label}>
            <input
              type="checkbox"
              className="column-checkbox"
              checked={column.checked}
              onClick={(event: React.MouseEvent<HTMLInputElement>) => {
                const target = event.target as HTMLInputElement;

                const tablesConfig = JSON.parse(localStorage.tablesConfig);

                if (target.checked) {
                  const index = tablesConfig[tableTitle].indexOf(column.label);
                  if (index !== -1) {
                    let arrayOfColumns = tablesConfig[tableTitle];
                    arrayOfColumns.splice(index, 1);

                    localStorage.tablesConfig = JSON.stringify({ ...tablesConfig, [tableTitle]: arrayOfColumns });
                  }
                } else {
                  localStorage.tablesConfig = JSON.stringify({
                    ...tablesConfig,
                    [tableTitle]: [...tablesConfig[tableTitle], column.label],
                  });
                }
                setColumnsState([
                  ...columnsState.map((el) => {
                    if (el.label === column.label) {
                      return { ...el, checked: !el.checked };
                    }
                    return el;
                  }),
                ]);
              }}
            />
            {column.label || ""}
          </div>
        );
      })}

      <Button
        variant="success"
        onClick={() => {
          handleSave();
        }}
        style={{ marginTop: 30 }}
      >
        Save changes
      </Button>
    </div>
  );
};

export default EditDisplayedColumnsModal;
