import React, { useState } from "react";
import { useSelector } from "react-redux";
import StoryReach from "./StoryReach";
import SocialScore from "./SocialScore";
import StreamTable from "./StreamTable";
import StoryValueEngine from "./StoryValueEngine";
import Numbers from "./Numbers";
import Button from "../ui/common/Button";
import Modal from "../ui/modal/Modal";
import { mainGraphData } from "./__mocks__/mainGraphData";
import { formatDisplayDate } from "../../helpers/dateHelper";
import { CircleLoader } from "../../icons/Loaders";

import "./Story.scss";

const PagesStory = () => {
  const [modalShown, setModalShown] = useState(false);
  const loading = useSelector((state: any) => state.activeFeedItem.isLoading);
  const activeItem = useSelector((state: any) => state.activeFeedItem.feedItem);

  return (
    <div className="story-container">
      <Modal
        isOpen={modalShown}
        onClose={() => {
          setModalShown(false);
        }}
      >
        This is the Modal
      </Modal>

      <header>
        {loading ? (
          <div className="title-loader-container">
            <CircleLoader />
          </div>
        ) : (
          <>
            <h1>{activeItem?.post?.title}</h1>
          </>
        )}

        {/* <Button
          variant="success"
          onClick={() => {
            setModalShown(true);
          }}
        >
          +
        </Button> */}
      </header>
      <main>
        <StoryReach mainGraphData={mainGraphData} circleGraphData={activeItem?.totals} circleLoading={loading} />
        {/* <SocialScore engagementData={activeItem?.engagement} loading={loading} /> */}
        {/* <StreamTable data={activeItem?.post?.timeline} loading={loading} /> */}
        {/* <StoryValueEngine
          author={activeItem?.post?.author}
          section={activeItem?.post?.section}
          reach={activeItem?.totals?.page_view_story_expectation}
          socialScore={activeItem?.engagement?.engagement_story_expectation}
          loading={loading}
        /> */}
        <Numbers loading={loading} hideFacebookNumbers />
      </main>
    </div>
  );
};

export default PagesStory;
