import React from "react";
import { LogoIcon } from "../icons/Icons";
import "./LoadingPage.scss";

const LoadingPage = () => {
  return (
    <div className="loading-page-container">
      <div>
        <div className="please-wait-message">Please wait...</div>
        <div className="logo-container">
          <LogoIcon />
        </div>
        <div className="loading-message">... is loading</div>
      </div>
    </div>
  );
};

export default LoadingPage;
