import React, { useMemo, useState } from "react";
import { useAppDispatch } from "../../store";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import HexagonGraph from "../graphs/HexagonGraph";
import StackedAreaGraphContainer from "../graphs/StackedAreaGraph/StackedAreaGraphContainer";
import BarChartContainer from "../graphs/BarChart/BarChartContainer";
import Select from "../ui/select/Select";
import { CircleLoader } from "../../icons/Loaders";
import { fetchActiveFeedItem } from "../../store/slices/activeFeedItem";
import "./StoryReach.scss";
import "./SocialScore.scss";

type SocialScoreProps = {
  engagementData: Array<any>,
  loading: boolean,
  type?: string,
  secondaryPosts: Array<any>,
};

const SocialScore = ({ engagementData, loading, type = "", secondaryPosts }: SocialScoreProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [selectedPost, setSelectedPost] = useState(-1);

  const PreparedHexagonChartData = useMemo(() => {
    if (!engagementData) return {};
    return {
      expectation: engagementData.expectation,
      factor: engagementData.engagement_factor,
      index: engagementData.engagement_story_expectation_index,
      // name: "Reach",
      story_expectation: engagementData.engagement_story_expectation,
      story_expectation_index: engagementData.engagement_story_expectation_index,
      value: engagementData.performance,
    };
  }, [engagementData]);

  const PreparedAreaGraphData = useMemo(() => {
    if (!engagementData) return [];

    if (type === "videos") {
      const youTubeChannel = engagementData?.channels?.find((e) => e.channel === "youtube");
      if (!youTubeChannel) return [];
      const likesData = youTubeChannel.metrics.find((e) => e.name === "Likes").graphdata[0];
      const commentsData = youTubeChannel.metrics.find((e) => e.name === "Comments").graphdata[0];
      const favoritesData = youTubeChannel.metrics.find((e) => e.name === "Favorites").graphdata[0];
      const dislikesData = youTubeChannel.metrics.find((e) => e.name === "Dislikes").graphdata[0];

      let sumData = [];

      for (let i = 0; i < likesData.length; i++) {
        const timestamp = likesData[i][0];

        const sum = likesData[i][1] + commentsData[i][1] + favoritesData[i][1] + dislikesData[i][1];
        sumData.push([timestamp, sum]);
      }

      const graphData = [
        {
          color: youTubeChannel.color,
          data: [...sumData],
          id: youTubeChannel.channel,
          name: youTubeChannel.name,
          total: youTubeChannel.performance,
        },
      ];
      return graphData;
    } else {
      const facebookChannel = engagementData?.channels?.find((e) => e.channel === "facebook");
      if (!facebookChannel) return [];
      const likesData = facebookChannel.metrics.find((e) => e.name === "Likes").graphdata[0];
      const commentsData = facebookChannel.metrics.find((e) => e.name === "Comments").graphdata[0];
      const sharesData = facebookChannel.metrics.find((e) => e.name === "Shares").graphdata[0];
      let sumData = [];

      for (let i = 0; i < likesData.length; i++) {
        const timestamp = likesData[i][0];

        const sum = likesData[i][1] + commentsData[i][1] + sharesData[i][1];
        sumData.push([timestamp, sum]);
      }

      const graphData = [
        {
          color: facebookChannel.color,
          data: [...sumData],
          id: facebookChannel.channel,
          name: facebookChannel.name,
          total: facebookChannel.performance,
        },
      ];
      return graphData;
    }
  }, [engagementData]);

  const PreparedBarChartData = useMemo(() => {
    if (!engagementData) return [];
    if (type === "videos") {
      const youTubeChannel = engagementData?.channels?.find((e) => e.channel === "youtube");
      if (!youTubeChannel) return [];
      return youTubeChannel.metrics.map((metric) => {
        return {
          value: metric.count,
          color: metric.color,
          title: metric.name,
        };
      });
    } else {
      const facebookChannel = engagementData?.channels?.find((e) => e.channel === "facebook");
      if (!facebookChannel) return [];
      return facebookChannel.metrics.map((metric) => {
        return {
          value: metric.count,
          color: metric.color,
          title: metric.name,
        };
      });
    }
  }, [engagementData]);

  const postsList = useMemo(() => {
    if (!secondaryPosts) return [];

    let posts = secondaryPosts
      .filter((post) => post.post_type === "fb_post")
      .map((post) => {
        return { label: post.content, key: post.post_type_id, value: post.post_type_id };
      });
    posts.unshift({ label: "No post selected", key: -1, value: -1 });

    return posts;
  }, [secondaryPosts]);

  const currentSelectedPost = useMemo(() => {
    if (selectedPost === -1) return "No post selected";
    else
      return postsList.filter((post) => {
        return post.value === selectedPost;
      })[0].label;
  }, [postsList, selectedPost]);

  const handlePostChange = (post) => {
    setSelectedPost(post);
    if (post !== -1) {
      dispatch(
        fetchActiveFeedItem(id, {
          facebookPostId: post,
        }),
      );
    } else {
      dispatch(fetchActiveFeedItem(id));
    }
  };

  return (
    <div className="story-reach-container">
      <h3>{t("story.socialScore.title")}</h3>
      <div className="graphs-container">
        <div className="page-views-graph">
          <h4>{t("story.socialScore.socialInteractions")}</h4>

          {loading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              <p className="performance">
                {t("story.common.performance")} x {engagementData?.engagement_factor}
              </p>
              <div className="circle-graph-container">
                <HexagonGraph data={PreparedHexagonChartData} key={PreparedHexagonChartData.value} />
                <h2 className="performance-index">{engagementData?.engagement_calc_result}</h2>
              </div>
              <div className="pageviews-expectations">
                <p className="expectations-label">{t("story.common.storyExpectations")}</p>
                {engagementData?.engagement_story_expectation_index ? (
                  <p className="expectations-value">{engagementData.engagement_story_expectation_index}</p>
                ) : (
                  <p className="expectations-not-available">{t("story.common.notAvailable")}</p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="origins-graph">
          <h4>{t("story.socialScore.engagementFlow")}</h4>
          <div>
            {loading ? (
              <div className="loader-container">
                <CircleLoader />
              </div>
            ) : (
              <>
                {type !== "videos" && (
                  <div className="segment-select-container">
                    <Select
                      options={postsList}
                      setSelectedOption={(post) => {
                        handlePostChange(post);
                      }}
                      selectedOption={currentSelectedPost.substring(0, 25)}
                      styling={{
                        selectOptionsContainerClassName: "segment-dropdown-container",
                      }}
                      fullWidth
                    />
                  </div>
                )}

                <StackedAreaGraphContainer data={PreparedAreaGraphData} />
                {type === "videos" && (
                  <p className="video-graph-explanation">
                    The graph is calculated by smartocto, based on gathered data from various sources. In the numbers
                    selectedSections of this page you will find the precise data from multiple API's.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="details-graph">
          <h4>{t("story.common.details")}</h4>
          {loading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              {(engagementData?.performance || PreparedBarChartData.length > 0) && (
                <>
                  <p className="engagement-facebook-title">{type === "videos" ? "YouTube" : "Facebook"}</p>
                  <p className="engagement-performance-value">{engagementData?.performance}</p>
                  <BarChartContainer data={PreparedBarChartData} />
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SocialScore;
