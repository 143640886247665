import React, { useState, useEffect, useRef } from "react";
import "./Select.scss";

type OptionProps = {
  children: any;
  changeOption: Function;
  optionClassName?: string;
  rounded?: boolean;
  value?: any | null;
};

const Option = ({ children, changeOption, optionClassName, rounded, value }: OptionProps) => {
  return (
    <div
      className={[optionClassName || "option", rounded && "rounded"].join(" ")}
      onClick={() => {
        changeOption(value || children);
      }}
    >
      {children}
    </div>
  );
};

type SelectProps = {
  options: Array<any>;
  selectedOption: any;
  setSelectedOption: Function;
  rounded?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  styling?: {
    selectClassName?: string;
    selectOptionsContainerClassName?: string;
    optionClassName?: string;
  };
};

const Select = ({ options, selectedOption, setSelectedOption, rounded, fullWidth, disabled, styling }: SelectProps) => {
  const [optionsDisplayed, setOptionsDisplayed] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setOptionsDisplayed(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setOptionsDisplayed]);

  const changeOption = (value: any) => {
    setSelectedOption(value);
    setOptionsDisplayed(false);
  };

  return (
    <div className={`so-select ${fullWidth && "full-width"}`} ref={ref}>
      <div
        className={`${styling?.selectClassName || "select-value-container"} select-value-container
          ${rounded && "rounded"} ${disabled ? "disabled" : ""} ${optionsDisplayed ? "options-displayed" : null}`}
        onClick={() => {
          if (disabled) return;
          setOptionsDisplayed(!optionsDisplayed);
        }}
      >
        <span className="select-value">{selectedOption}</span>
        <span className="dropdown-icon">&#x2B9F;</span>
      </div>
      <div
        className={`select-options ${rounded && "rounded"} ${
          optionsDisplayed ? "options-displayed" : "options-hidden"
        } ${styling?.selectOptionsContainerClassName}`}
      >
        {options.map((e) => (
          <Option
            key={e.key || e}
            changeOption={changeOption}
            optionClassName={styling?.optionClassName}
            rounded={rounded}
            value={e.value || null}
          >
            {e.label || e}
          </Option>
        ))}
      </div>
    </div>
  );
};

export default Select;
