import React, { useState, useMemo, useCallback } from "react";
import { DateTime } from "luxon";

import StackedAreaGraph from "./StackedAreaGraph";
import StackedAreaGraphLegend from "./StackedAreaGraphLegend";
import { ICON_OPTIONS as iconOptions } from "./IconOptions";
import "./StackedAreaGraphContainer.scss";
import { useSelector } from "react-redux";

type StackedAreaGraphContainerProps = {
  data: any;
  secondaryPosts?: any;
  showLagend?: boolean;
  graphInitialWidth?: number;
};

const StackedAreaGraphContainer = ({
  data,
  secondaryPosts,
  showLagend = true,
  graphInitialWidth,
}: StackedAreaGraphContainerProps) => {
  const [deactivatedChannels, setDeactivatedChannels] = useState<Array<string>>([]);
  const [scrollLocked, setScrollLocked] = useState(true);

  const currentBrand = useSelector((state: any) => state.currentBrand.data);

  const transformedGraphData = useMemo(() => {
    const channelsWithData = [];
    const formatedData = [];
    data?.forEach((e) => {
      // @ts-ignore
      channelsWithData.push({ channel: e.id, data: e.data });
    });

    channelsWithData?.forEach((dataSet, dataSetIndex) => {
      // @ts-ignore
      dataSet?.data?.forEach((e, i) => {
        if (dataSetIndex === 0) {
          // @ts-ignore
          formatedData.push([]);
        }
        // @ts-ignore
        formatedData[i].timestamp = new Date(
          DateTime.fromMillis(e[0]).setZone(currentBrand.domainSettings.timezone).toFormat("yyyy-MM-dd HH:mm"),
        );
        // @ts-ignore
        if (!deactivatedChannels.includes(dataSet.channel)) {
          // @ts-ignore

          formatedData[i][dataSet.channel] = e[1];
        }
      });
    });

    return formatedData;
  }, [data, deactivatedChannels, currentBrand]);

  const preparedGraphIcons = useMemo(() => {
    if (!secondaryPosts || secondaryPosts === undefined) return [];
    else {
      const secondaryPostsMapped = secondaryPosts.map(function (post) {
        // Find out the homepage link
        if (post.post_type === "site_link" && post.sitename === "omproepbrabant") {
          post.post_type = "homepage_link";
        }
        return post;
      });

      const secondaryPostsFiltered = secondaryPostsMapped.filter((post) => {
        return post.post_type !== "tentaclePage_link";
      });

      const iconsPrepared = [] as Array<any>;

      secondaryPostsFiltered.map(function (post) {
        let postType = iconOptions[post.post_type];
        let date = new Date(post.display_date);
        date = new Date(
          DateTime.fromJSDate(date).setZone(currentBrand.domainSettings.timezone).toFormat("yyyy-MM-dd HH:mm"),
        );
        if (postType) {
          iconsPrepared.push({
            id: post.asset_id,
            date: date,
            title: post.sitename || postType?.name || "",
            type: post.post_type,
            icon: postType.icon,
            iconSVG: postType.iconSVG,
            height: 40,
          });
        }
      });

      return iconsPrepared.sort(function (a, b) {
        return a.date - b.date;
      });
    }
  }, [secondaryPosts]);

  const graphColors = useMemo(() => {
    const colors = {};
    data?.forEach((e) => {
      colors[e.id] = e.color;
    });
    return colors;
  }, [data]);

  const graphKeys = useMemo(() => {
    return data?.map((e) => e.id);
  }, [data]);

  const legendData = useMemo(() => {
    return data?.map((e) => {
      return {
        id: e.id,
        color: e.color,
        label: e.name,
        deactivated: deactivatedChannels.includes(e.id) ? true : false,
      };
    });
  }, [data, deactivatedChannels]);

  const handleLabelItemClick = useCallback((channelId: string) => {
    // @ts-ignore
    setDeactivatedChannels((prevState) => {
      const index = prevState.indexOf(channelId);
      if (index === -1) {
        return [...prevState, channelId];
      } else {
        return prevState.filter((item) => item !== channelId);
      }
    });
  }, []);

  return (
    <div className="stacked-area-graph-container">
      {scrollLocked ? (
        <div
          className="scroll-lock-overlay"
          onClick={() => {
            setScrollLocked(false);
          }}
        >
          <div className="click-to-interact">Click to interact</div>
        </div>
      ) : (
        <div
          className="lock-icon"
          onClick={() => {
            setScrollLocked(true);
          }}
        ></div>
      )}

      <StackedAreaGraph
        data={transformedGraphData}
        colors={graphColors}
        keys={graphKeys}
        icons={preparedGraphIcons}
        initialWidth={graphInitialWidth}
      />
      {showLagend && <StackedAreaGraphLegend data={legendData || []} itemClick={handleLabelItemClick} />}
    </div>
  );
};

export default StackedAreaGraphContainer;
