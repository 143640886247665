import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import MainNavigation from "../../components/navigation/MainNavigation";
import SideNavigation from "../../components/navigation/SideNavigation";
import Bundle from "../../components/bundle/Bundle";
import {
  fetchBundleGraphData,
  fetchBundleGraphDataChannel,
  fetchBundles,
  fetchSingleBundle,
} from "../../store/slices/bundles";
import { useNavigate, useParams } from "react-router";
import { CircleLoader } from "../../icons/Loaders";
import { useAppDispatch } from "../../store";
import { useSearchParams } from "react-router-dom";
import DataNotAvailable from "../../components/errors/DataNotAvailable";

const Bundles = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const bundleItems = useSelector((state: any) => state.bundles.data);
  const isLoading = useSelector((state: any) => state.bundles.isLoading);
  const [selectedFilter, setSelectedFilter]: [string, Function] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();

  useEffect(() => {
    document.title = "Bundles";
  }, []);

  useEffect(() => {
    const filter = searchParams.get("filter");
    setSelectedFilter(filter || "");

    dispatch(fetchBundles());
  }, [dispatch]);

  const transformedItems = useMemo(() => {
    return bundleItems.map((e) => {
      return {
        title: e.bundle_name,
        type: "secondary",
        postId: e.bundle_id,
        pubDate: e.bundle_last_activity?.bundle_lastupdate,
        bundleStoryCount: e.bundle_story_count.toString(),
      };
    });
  }, [bundleItems]);

  const postId = transformedItems[0]?.postId;

  useEffect(() => {
    if (!id && transformedItems[0]?.postId) {
      navigate(`/bundles/${transformedItems[0].postId}`);
    }
  }, [postId, id, navigate, transformedItems]);

  useEffect(() => {
    if (!id) return;
    dispatch(fetchSingleBundle(id));
    dispatch(fetchBundleGraphData(id));
    dispatch(fetchBundleGraphDataChannel(id));
  }, [id, dispatch]);

  const filters = [
    {
      filter: "Big",
      selected: false,
    },
    {
      filter: "Fast",
      selected: false,
    },
  ];

  const pageContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircleLoader />
        </div>
      );
    }
    if (transformedItems.length === 0) {
      return <DataNotAvailable pageType="bundles" />;
    }
    return <Bundle />;
  }, [isLoading]);

  return (
    <div>
      <MainNavigation />
      <div className="container">
        <SideNavigation
          title="Bundles"
          items={transformedItems}
          filters={filters}
          isLoading={isLoading}
          filtersMessage="Sort bundles by"
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          page="bundles"
        />
        <div className="main-page-container">
          <div className="story-container">{pageContent}</div>
        </div>
      </div>
    </div>
  );
};

export default Bundles;
