import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import Table from "../../../components/ui/table/Table";
import MainNavigation from "../../../components/navigation/MainNavigation";
import { fetchAllBrands } from "../../../store/slices/brands";
import Button from "../../../components/ui/common/Button";
import { EditIcon, DeleteIcon, PlusIcon } from "../../../icons/Icons";
import { CircleLoader } from "../../../icons/Loaders";
import Modal from "../../../components/ui/modal/Modal";

import "../Admin.scss";
import EditBrandModal from "../../../components/modals/EditBrandModal/EditBrandModal";

const Brands = () => {
  const dispatch = useAppDispatch();
  const [editingBrand, setEditingBrand] = useState(false);
  const [brandEditData, setBrandEditData] = useState({});
  const [creatingNewBrand, setCreatingNewBrand] = useState(false);
  const brands = useSelector((state: any) => state.brands.allBrandsData);
  const isLoading = useSelector((state: any) => state.brands.isLoadingAllBrands);

  useEffect(() => {
    document.title = "Admin - Brands - Smartocto real-time";
  }, []);

  //@ts-ignore
  const timeZones = Intl.supportedValuesOf("timeZone");
  // console.log("timezones", timeZones);

  const handleEdit = (id) => {};
  const handleDelete = (id) => {};

  const columns = [
    { title: "id", label: "id" },
    { title: "active", label: "Active" },
    { title: "features", label: "Features" },
    { title: "actions", label: "Actions" },
  ];

  const dataWithActions = brands.map((e, i) => {
    return {
      // ...e,
      id: e.id?.value,
      active: e.active?.value ? "yes" : "no",
      features: e.features.value?.join(", "),
      sort: {id: e.id?.value, active: e?.active?.value, features: (e.features && Array.isArray(e.features.value)) ? e.features.value?.join(" "): ''},
      actions: (
        <div style={{ display: "flex" }}>
          <Button
            variant="warning"
            data-testid={`editButton${i}`}
            size="small"
            style={{ marginLeft: "10px", marginRight: "10px" }}
            onClick={() => {
              setEditingBrand(true);
              setBrandEditData(e);
            }}
          >
            <EditIcon />
          </Button>
          <Button
            variant="danger"
            data-testid={`deleteButton${i}`}
            size="small"
            onClick={() => {
              handleDelete(e.id);
            }}
          >
            <DeleteIcon />
          </Button>
        </div>
      ),
    };
  });

  const handleSetNewBrandCreation = () => {
    let newBrandTemplate = {
      ...brands.filter((item) => {
        return item.id.value === "systembrand";
      })[0],
    };
    newBrandTemplate.id = {
      value: "",
      default: "",
    };
    newBrandTemplate.name = {
      value: "",
      default: "",
    };
    newBrandTemplate.apiToken = {
      value: "",
      default: "",
    };
    setBrandEditData({ ...newBrandTemplate });
    setCreatingNewBrand(true);
    setEditingBrand(true);
  };

  useEffect(() => {
    dispatch(fetchAllBrands());
  }, []);

  return (
    <>
      <Modal
        isOpen={editingBrand}
        onClose={() => {
          setEditingBrand(false);
          setCreatingNewBrand(false);
        }}
      >
        <EditBrandModal brand={brandEditData} newBrand={creatingNewBrand} />
      </Modal>
      <MainNavigation />
      <div className="page-container-no-side-nav">
        <h1 className="admin-page-title">Brands </h1>

        <div className="admin-page-header-btns">
          <Button
            style={{ marginLeft: 20, padding: "6px 8px" }}
            variant="success"
            onClick={() => {
              handleSetNewBrandCreation();
            }}
          >
            <PlusIcon width="16px" height="16px" />
          </Button>
        </div>
        {!isLoading ? (
          <div className="table-container">
            <Table showRowNumbers={true} rowData={dataWithActions} columnsData={columns} />
          </div>
        ) : (
          <div className="loader-container">
            <CircleLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Brands;
