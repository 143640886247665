import React from "react";
import BarChart from "./BarChart";
import BarChartLegend from "./BarChartLegend";

const BarChartContainer = ({ data }: { data: any }) => {
  return (
    <>
      <BarChart data={data} height={250} mode="default" />
      <BarChartLegend data={data} />
    </>
  );
};

export default BarChartContainer;
