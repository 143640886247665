import React, { useEffect } from "react";
import AdminCard from "../../components/ui/cards/AdminCard";
import MainNavigation from "../../components/navigation/MainNavigation";
import useIsFeatureAllowed from "../../customHooks/useIsFeatureAllowed";
import MobileNavigation from "../../components/navigation/MobileNavigation";
import { useSelector } from "react-redux";
import "../Admin/Admin.scss";

const Logbook = () => {
  const isFeatureAllowed = useIsFeatureAllowed();
  const isMobileNavigationShown = useSelector((state: any) => state.mobile.isNavigationOpen);

  useEffect(() => {
    document.title = "Logbook - smartocto real-time";
  }, []);

  const getFeatureAbsolutePath = (feature: string) => {
    const hostname = window.location.hostname;
    const hostnamesplited = hostname.split(".");
    let link = "";
    if (hostnamesplited.length === 1) {
      link = `https://${hostnamesplited[0].split("-")[0]}-staging-${feature}.smartocto.com`;
    } else if (hostnamesplited.length === 3 && hostnamesplited[0].indexOf("staging") > 0) {
      link = `https://${hostnamesplited[0].split("-")[0]}-staging-${feature}.smartocto.com`;
    } else {
      link = `https://${hostnamesplited[0]}-${feature}.smartocto.com`;
    }
    return link;
  };

  const cardOptions = [
    {
      id: 1,
      title: "Impact Radar",
      description: "Smart, dynamic timeline showing the impact of your hard work. Improve the effect of your efforts.",
      linkPath: "impact-radar",
      illustration: "impact-radar",
    },
    {
      id: 2,
      title: "Coral Reef",
      description:
        "Here we test new Smartocto tools. Currently we're working on something pretty awesome. Unfortunately not ready to test, so stay tuned.",
      linkPath: "users",
      illustration: "coral-reef",
      comingSoon: true,
    },
    {
      id: 3,
      title: "Current Video",
      description: "Is your effort creating waves right now? Check out your latest Catch and On Board results.",
      linkPath: "/reports/currentVideo/ob",
      illustration: "coral-reef",
      disabled: !isFeatureAllowed("videos"),
    },
    isFeatureAllowed("waves") && {
      id: 4,
      title: "Multibrand",
      description: "Your statistics overview per selectedSections. Stay up to date.",
      linkPath: getFeatureAbsolutePath("waves"),
      illustration: "tide-table",
    },
    {
      id: 5,
      title: "Avg. Readingtime",
      description: "Get an overview of what story got the most attention of your visitors.",
      linkPath: "/reports/current/readingtime",
      illustration: "shark-attack",
      disabled: !isFeatureAllowed("readingtime"),
    },
    {
      id: 6,
      title: "Tentacles AB-tests",
      description: "List and manage of all your AB-tests of the last days.",
      linkPath: "/reports/tentacles",
      illustration: "sea-turtle",
      disabled: !isFeatureAllowed("tentacles"),
    },
    {
      id: 7,
      title: "Smartify",
      description: "Your step-by-step editorial decision making system.",
      linkPath: getFeatureAbsolutePath("smartify"),
      absolutePath: true,
      illustration: "smartify",
      disabled: !isFeatureAllowed("smartify"),
    },
    {
      id: 8,
      title: "Notification Tracker",
      description: "Manage all your notifications to have full control.",
      linkPath: `${getFeatureAbsolutePath("smartify")}/tracker`,
      absolutePath: true,
      illustration: "notifictation-manager",
    },
    {
      id: 9,
      title: "Waves",
      description: "Manage all your dashboards",
      linkPath: getFeatureAbsolutePath("waves"),
      absolutePath: true,
      illustration: "channel-treasures",
      disabled: !isFeatureAllowed("waves"),
    },
    {
      id: 10,
      title: "Goals",
      description: "Set up onetime or recurring goals",
      linkPath: `${getFeatureAbsolutePath("waves")}/admin/goals`,
      absolutePath: true,
      illustration: "goals",
      disabled: !isFeatureAllowed("goals"),
    },
    isFeatureAllowed("recommender") && {
      id: 11,
      title: "RPO Recommender",
      description: "",
      linkPath: "/recommender",
      illustration: "smartify",
    },
    isFeatureAllowed("ai") && {
      id: 12,
      title: "Credits",
      description: "See what you have used so far from your smartocto AI credits",
      linkPath: "/reports/credits",
      illustration: "ai-credits",
    },
  ];

  return (
    <>
      <MainNavigation />
      <MobileNavigation title="Logbook" />
      <div className={`cards-container ${isMobileNavigationShown ? "mobile-menu-shown" : ""}`}>
        {cardOptions.map((option) => {
          if (!option) return;
          // @ts-ignore
          return <AdminCard {...option} key={option.id} />;
        })}
      </div>
    </>
  );
};

export default Logbook;
