import React from "react";
import Input from "../../ui/common/Input";

type StatisticsSettingsProps = {
  showStatisticsSettings: boolean;
  handleToggleStatistics: Function;
};

const StatisticsSettings = ({ showStatisticsSettings, handleToggleStatistics }: StatisticsSettingsProps) => {
  return (
    <div>
      <div
        className="brand-settings-title first"
        onClick={() => {
          handleToggleStatistics();
        }}
      >
        Statistics
      </div>

      <div className={["brand-settings-container", "statistics", showStatisticsSettings && "active"].join(" ")}>
        <div className="statistics-wrapper">
          <div>
            <h4>At Internet Auth</h4>
            <Input label="Client Email" separateLines />
            <Input label="Private Key" separateLines />
          </div>
          <div>
            <h4>Ga Auth</h4>
            <Input label="Client Email" separateLines />
            <Input label="Private Key" separateLines />
          </div>
        </div>

        {/* <div className="sub-modal-actions">
        <Button>Cancel</Button>
        <Button>OK</Button>
      </div> */}
      </div>
    </div>
  );
};

export default StatisticsSettings;
