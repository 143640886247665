import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../store";
import MainNavigation from "../../components/navigation/MainNavigation";
import SideNavigation from "../../components/navigation/SideNavigation";
import Story from "../../components/story/Story";
import { fetchFeedVideosItems } from "../../store/slices/feed";
import {
  fetchActiveFeedItem,
  fetchActiveFeedItemCVI,
  fetchActiveFeedItemNumbers,
} from "../../store/slices/activeFeedItem";
import { useNavigate, useParams } from "react-router";
import { CircleLoader } from "../../icons/Loaders";
import { v4 as uuidv4 } from "uuid";
import { getDayNameFromDate } from "../../helpers/dateHelper";
import DataNotAvailable from "../../components/errors/DataNotAvailable";
import { useSearchParams } from "react-router-dom";

const Videos = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const items = useSelector((state: any) => state.feed.videosData?.posts);
  const { dashboardSettings } = useSelector((state: any) => state.currentBrand.data);
  const [selectedFilter, setSelectedFilter]: [string, Function] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading } = useSelector((state: any) => state.feed);
  const { id } = useParams();

  const filter = searchParams.get("filter");
  const search = searchParams.get("q");

  // Initial fetching of videos
  useEffect(() => {
    setSearchTerm(search || "");
    setSelectedFilter(filter || "");
    dispatch(fetchFeedVideosItems(filter || "page", search || ""));
  }, [dispatch]);

  // Interval refetching of videos
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchFeedVideosItems(filter || "page", search || ""));
    }, dashboardSettings?.storyListRefreshTime || 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const transformedItems = useMemo(() => {
    if (!items) return [];
    let currentDay = "";
    return items.map((e) => {
      const day = getDayNameFromDate(e.pubdate);
      const item = {
        ...e,
        type: "secondary",
        postId: e.post_id,
        pubDate: e.pubdate,
        key: uuidv4(),
      };
      if (day !== currentDay) {
        currentDay = day;
        return {
          ...item,
          day: currentDay,
        };
      }
      return item;
    });
  }, [items]);

  useEffect(() => {
    if (!id && transformedItems[0]?.postId) {
      navigate(`/videos/${transformedItems[0].postId}`);
    }
  }, [transformedItems, id, navigate]);

  useEffect(() => {
    if (!id) return;
    dispatch(
      fetchActiveFeedItem(id, {
        primaryStoryType: "videos",
      }),
    );
    dispatch(fetchActiveFeedItemCVI(id, "videos"));
    dispatch(fetchActiveFeedItemNumbers(id, { primaryStoryType: "videos" }));
  }, [id, dispatch]);

  const filters = [
    {
      filter: "Big",
      selected: false,
    },
    {
      filter: "Fast",
      selected: false,
    },
  ];

  const pageContent = useMemo(() => {
    if (isLoading) {
      return (
        <div className="loader-container">
          <CircleLoader />
        </div>
      );
    }
    if (transformedItems.length === 0) {
      return <DataNotAvailable pageType="videos" />;
    }
    return <Story type="videos" />;
  }, [isLoading]);

  return (
    <div>
      <MainNavigation />
      <div className="container">
        <SideNavigation
          title="Videos"
          items={transformedItems}
          filters={filters}
          isLoading={isLoading}
          fetchFunction={fetchFeedVideosItems}
          hasSearch
          searchFunction={fetchFeedVideosItems}
          filtersMessage={"Sort videos by"}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
          page="videos"
        />
        <div className="main-page-container">{pageContent}</div>
      </div>
    </div>
  );
};

export default Videos;
