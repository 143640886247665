import React from "react";
import Input from "../../../../ui/common/Input";
import TextArea from "../../../../ui/common/TextArea";
import "./TentaclesSettingsForms.scss";

const ClassificationsForm = ({ data, handleChanges }) => {
  return (
    <div className="inputs-container">
      <Input
        value={data.classificationsUpdateRate.value || data.classificationsUpdateRate.default}
        type="number"
        onChange={(e) => {
          handleChanges("classificationsUpdateRate", e.target.valueAsNumber);
        }}
        label="Classifications Update Rate"
        fullWidth
        separateLines
      />
      <TextArea
        className="textarea-code-editor"
        label="Clicks"
        value={data.clicks.value || data.clicks.default}
        onChange={(e) => {
          handleChanges("clicks", e.target.value);
        }}
      />
      <TextArea
        className="textarea-code-editor"
        label="Loyalty"
        value={data.loyalty.value || data.loyalty.default}
        onChange={(e) => {
          handleChanges("loyalty", e.target.value);
        }}
      />
    </div>
  );
};

export default ClassificationsForm;
