import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  isLoading: boolean;
  data: Array<Object>;
  error: Object | null;
};

const INITIAL_STATE = {
  isLoading: false,
  data: [],
  error: null,
};

const credits = createSlice({
  name: "credits",
  initialState: INITIAL_STATE,
  reducers: {
    fetchCreditsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchCreditsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchCreditsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default credits.reducer;

const { fetchCreditsStart, fetchCreditsSuccess, fetchCreditsFailed } = credits.actions;

export const fetchCredits = () => async (dispatch: Function) => {
  try {
    dispatch(fetchCreditsStart());
    const api = new Api();
    const result = await api.getCredits();
    dispatch(fetchCreditsSuccess(result));
  } catch (err) {
    dispatch(fetchCreditsFailed(err));
  }
};
