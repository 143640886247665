import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

const ImpactRadarGraph = ({ data, effortData, impactData, startDate, endDate, selectedChannel }) => {
  const svgRef = useRef();

  const margin = { top: 50, right: 50, bottom: 50, left: 80 };
  const xAxisOffset = 30;
  const yAxisOffset = 30;
  const xAxisMargin = 10;
  const yAxisMargin = 10;
  const width = 1750 - margin.left - margin.right;
  const height = 700 - margin.top - margin.bottom;

  const maxCircleRadius = 24.7;

  useEffect(() => {
    let maxEffort = d3.max(effortData, function (d) {
      return d.value;
    });
    let maxImpact = d3.max(impactData, function (d) {
      return d.value;
    });
    let maxValue = Math.max(maxEffort, maxImpact);

    d3.select(svgRef.current).selectAll("*").remove();

    const svg = d3
      .select(svgRef.current)
      .attr("width", width + margin.left + margin.right + yAxisOffset)
      .attr("height", height + margin.top + margin.bottom + xAxisOffset)
      .append("g")
      .attr(
        "transform",
        `translate(${margin.left + yAxisOffset + yAxisMargin},${margin.top + xAxisOffset + xAxisMargin})`,
      );

    // Parse date and time
    const parseTime = d3.utcParse("%s");

    // Set up scales
    const xScale = d3.scaleLinear().range([0, width]);
    // const xScale = d3.scaleLinear().range([6, width + 6]);
    const yScale = d3.scaleLinear().range([0, height + margin.top + margin.bottom]);

    const validEffortData = effortData.filter((d) => !isNaN(d.date));
    const validImpactData = impactData.filter((d) => !isNaN(d.date));

    // Update scales domain
    // xScale.domain([d3.min(validEffortData, (d) => d.hour), d3.max(validEffortData, (d) => d.hour)]);
    xScale.domain([6, 23 + 6]);
    // yScale.domain([d3.min(validEffortData, (d) => d.date), d3.max(validEffortData, (d) => d.date)]);
    yScale.domain([startDate, endDate]);

    // Set up axes
    const xAxis = d3
      .axisBottom(xScale)
      // .ticks(24)
      .ticks(Math.min(23, parseInt(width / 30, 10)))
      .tickFormat((d) => {
        return String(d % 24).padStart(2, "0");
      });

    const formatDate = (date, increment) => {
      const dateFormatted = new Date(date?.getUTCFullYear(), date.getMonth(), date.getUTCDate());
      dateFormatted.setDate(dateFormatted.getDate() + increment);
      return dateFormatted;
    };

    const tickValues = [];

    for (let index = 0; index < 7; index++) {
      tickValues.push(formatDate(startDate, index));
    }
    const yAxis = d3.axisLeft(yScale).tickFormat(d3.timeFormat("%a %d %b")).tickValues(tickValues).ticks(7);
    // Draw axes

    const xAxisElemnt = svg
      .append("g")
      .attr("class", "x-axis")
      .attr("transform", `translate(0,${height + xAxisMargin})`)
      .call(xAxis);

    svg
      .append("g")
      .attr("class", "y-axis")
      .call(yAxis)
      .selectAll("text")
      .attr("x", "-25")
      .attr("class", "y-axis-label");

    // Draw effort circles
    svg
      .selectAll("circle-effort")
      .data(validEffortData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.hour))
      .attr("cy", (d) => {
        if (d.hour > 23 && d.hour < 30) {
          let yesterday = new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`);
          yesterday.setDate(yesterday.getDate() - 1);
          return yScale(yesterday) - 10;
        } else {
          return yScale(new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`)) - 10;
        }
      })
      .attr("r", 0) // Start with radius 0
      .attr("fill", "#1abcae")
      .attr("class", `color-${selectedChannel}-effort`)
      .transition() // Start the transition on the circle element
      .duration(500) // Duration of the transition in milliseconds
      .delay((d) => d.hour * 10)
      .attr("r", (d) => (d.value / maxEffort) * maxCircleRadius) // Transition to the calculated radius
      .selection() // This ensures that the transition applies correctly
      .append("svg:title")
      .text((d) => {
        // return `${d.dateD} ${d.date} ${d.timestamp}`;
        // return `${d.timestamp}`;
      });

    // Draw impact circles
    svg
      .selectAll("circle-impact")
      .data(validImpactData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.hour))
      .attr("cy", (d) => {
        if (d.hour > 23 && d.hour < 30) {
          let yesterday = new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`);
          yesterday.setDate(yesterday.getDate() - 1);
          return yScale(yesterday) + 20;
        } else {
          return yScale(new Date(`${d.dateD.c.year}-${d.dateD.c.month}-${d.dateD.c.day}`)) + 20;
        }
      })
      .attr("r", 0) // Start with radius 0
      .attr("fill", "#4381a6")
      .attr("class", `color-${selectedChannel}-impact`)
      .transition() // Start the transition on the circle element
      .duration(500) // Duration of the transition in milliseconds
      .delay((d) => d.hour * 10)
      .attr("r", (d) => (d.value / maxImpact) * maxCircleRadius) // Scale the circle size based on data value
      .selection()
      .append("svg:title")
      .text((d) => {
        // return `${d.dateD} ${d.date} ${d.timestamp}`;
        // return `${d.timestamp}`;
      });

    svg
      .selectAll(".x-axis line")
      .attr("stroke", "rgba(151,151,151,.3)")
      .attr("fill", "none")
      .attr("y0", -20)
      .attr("y2", function (d) {
        return -(height + 90);
      });

    svg.selectAll(".y-axis .domain").attr("stroke", "");
    svg.selectAll(".y-axis line").attr("stroke", "");
    svg.selectAll(".x-axis .domain").attr("stroke", "");
  }, [data, effortData, impactData, startDate, endDate, selectedChannel]);

  return <svg ref={svgRef} width="1000"></svg>;
};

export default ImpactRadarGraph;
