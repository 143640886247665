import React, { useMemo, useState, useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import CircleGraph from "../graphs/CircleGraph";
import StackedAreaGraphContainer from "../graphs/StackedAreaGraph/StackedAreaGraphContainer";
import BarChartContainer from "../graphs/BarChart/BarChartContainer";
import Select from "../ui/select/Select";
import { CircleLoader } from "../../icons/Loaders";
import "./StoryReach.scss";
import { useTranslation } from "react-i18next";
import {
  setSelectedSegment,
  fetchActiveFeedItemSegmentCVI,
  fetchActiveFeedItemCVI,
} from "../../store/slices/activeFeedItem";

type Props = {
  mainGraphData: any,
  circleGraphData?: any,
  circleLoading?: boolean,
  secondaryPosts?: any,
  currentPost?: any,
  type?: string,
};

const StoryReach = ({
  mainGraphData,
  secondaryPosts,
  circleGraphData,
  circleLoading,
  currentPost,
  type = "",
}: Props) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const activeItemCVI = useSelector((state: any) => state.activeFeedItem.cviData?.graphdata);
  const isLoading = useSelector((state: any) => state.activeFeedItem.isLoadingCVI);
  const isLoadingSegments = useSelector((state: any) => state.activeFeedItem.isLoadingSegments);
  const segments = useSelector((state: any) => state.activeFeedItem.segmentsData);
  const selectedSegment = useSelector((state: any) => state.activeFeedItem.selectedSegment);

  useEffect(() => {
    if (selectedSegment.label === "") {
      dispatch(setSelectedSegment({ key: "No segments selected", label: "No segments selected" }));
    }
  }, [selectedSegment]);

  const data =
    activeItemCVI?.map((e) => {
      return {
        value: parseInt(e.total, 10),
        color: e.color,
        title: e.name,
      };
    }) || [];

  const circleData = useMemo(() => {
    return {
      expectation: circleGraphData?.page_view_expectation || 0,
      factor: circleGraphData?.page_view_factor || 0,
      index: circleGraphData?.page_view_index || 0,
      name: "Reach",
      story_expectation: circleGraphData?.page_view_story_expectation || null,
      story_expectation_index: circleGraphData?.page_view_story_expectation_index || 0,
      value: circleGraphData?.page_view_count || 0,
    };
  }, [circleGraphData]);

  const segmentsList = useMemo(() => {
    if (segments instanceof Array && segments.length) {
      const allSegments = segments.map((e) => {
        return e.segment_name;
      });
      allSegments.unshift("No segments selected");
      return allSegments;
    }

    return [];
  }, [segments]);

  const handleSegmentChange = (segmentName) => {
    if (segmentName === "No segments selected") {
      dispatch(setSelectedSegment({ key: segmentName, label: segmentName }));
      dispatch(fetchActiveFeedItemCVI(id));
    } else {
      const segment = segments.filter((e) => {
        return e.segment_name === segmentName;
      })[0];
      dispatch(setSelectedSegment({ key: segment.segment_id, label: segment.segment_name }));
      dispatch(fetchActiveFeedItemSegmentCVI(id, segment.segment_id));
    }
  };

  const preparedSecondaryPosts = useMemo(() => {
    if (!currentPost) return secondaryPosts;
    return [
      ...secondaryPosts,
      {
        asset_id: currentPost.asset_id,
        content: currentPost.content,
        display_date: currentPost.display_date,
        payedArticle: currentPost.payedArticle,
        post_id: currentPost.post_id,
        post_type: currentPost.post_type,
        post_url: currentPost.post_url,
        section: currentPost.section,
        title: currentPost.title,
      },
    ];
  }, [secondaryPosts, currentPost]);

  return (
    <div className="story-reach-container">
      <h3>{t("story.reach.title")}</h3>
      <div className="graphs-container">
        <div className="page-views-graph">
          <h4>{t("story.reach.pageviews")}</h4>
          {circleLoading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              <p className="performance">
                {t("story.common.performance")} x {circleData.factor}
              </p>
              <div className="circle-graph-container">
                <CircleGraph data={circleData} key={circleData.value} />
                <h2 className="performance-index">{circleData.index}</h2>
              </div>
              <div className="pageviews-expectations">
                <p className="expectations-label">{t("story.common.storyExpectations")}</p>
                {circleData.story_expectation_index ? (
                  <p className="expectations-value">{circleData.story_expectation_index}</p>
                ) : (
                  <p className="expectations-not-available">{t("story.common.notAvailable")}</p>
                )}
              </div>
            </>
          )}
        </div>
        <div className="origins-graph">
          <h4>{t("story.reach.origins")}</h4>
          <div>
            {isLoading ? (
              <div className="loader-container">
                <CircleLoader />
              </div>
            ) : (
              <>
                {type !== "videos" && (
                  <div className="segment-select-container">
                    <Select
                      options={segmentsList}
                      setSelectedOption={(segment) => {
                        handleSegmentChange(segment);
                      }}
                      selectedOption={selectedSegment.label}
                      styling={{
                        selectOptionsContainerClassName: "segments-dropdown-container",
                      }}
                      fullWidth
                    />
                  </div>
                )}
                <StackedAreaGraphContainer data={activeItemCVI} secondaryPosts={preparedSecondaryPosts} />
                {type === "videos" && (
                  <p className="video-graph-explanation">
                    The graph is calculated by smartocto, based on gathered data from various sources. In the numbers
                    selectedSections of this page you will find the precise data from multiple API's.
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="details-graph">
          <h4>{t("story.common.details")}</h4>
          {isLoading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <>
              <p className="performance">{t("story.common.performance")} x 100</p>
              <BarChartContainer data={data} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StoryReach;
