import React, { useState, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import TextArea from "../../ui/common/TextArea";
import ToggleSwitch from "../../ui/toggleSwitch/ToggleSwitch";
import Select from "../../ui/select/Select";
import { useAppDispatch } from "../../../store";
import { fetchBrands } from "../../../store/slices/brands";
import { updateImport } from "../../../store/slices/imports";
import "./EditImportModal.scss";

const EditImportModal = ({ data, newImport, handleClose }) => {
  const dispatch = useAppDispatch();
  const importTypes = useSelector((state: any) => state.imports.importTypes);
  const brands = useSelector((state: any) => state.brands.data);
  const [importId, setImportId] = useState(data.id);
  const [brandId, setBrandId] = useState(data.brandId);
  const [type, setType] = useState(data.type);
  const [active, setActive] = useState(data.active);
  const [insertMethod, setInsertMethod] = useState(data.insertMethod);
  const [mapping, setMapping] = useState(JSON.stringify(data.mapping, null, 2));
  const [params, setParams] = useState<any>({});
  const InsertMethods = ["insert", "update"];

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  useEffect(() => {
    // set initial values of param feilds based on type and initial data
    if (!type) return;
    const availableParams = importTypes[type];
    let preparedValuesForParams = {};
    if (availableParams) {
      availableParams.forEach((element) => {
        if (element.field === "wildcardFilters") {
          if (data[element.field] !== undefined) {
            preparedValuesForParams = {
              ...preparedValuesForParams,
              [element.field]: JSON.stringify(data[element.field], null, 2),
            };
          }
        } else if (data[element.field] !== undefined) {
          preparedValuesForParams = {
            ...preparedValuesForParams,
            [element.field]: data[element.field],
          };
        }
        setParams({
          ...params,
          ...preparedValuesForParams,
        });
      });
    }
  }, [importTypes, type, data]);

  useEffect(() => {
    // set initial defauilt values when creating new Import
    if (!newImport) return;
    let initialParamValues = {};

    const prepareObjectsAndArraysForCodeEdit = (type, value) => {
      if (type === "array" || type === "object") return JSON.stringify(value, null, 2);
      return value;
    };

    importTypes[type]?.forEach((param) => {
      if (param.field === "filter" || param.filte === "wildcardFilters") {
        initialParamValues[param.field] = JSON.stringify(param.default);
      } else {
        initialParamValues[param.field] = prepareObjectsAndArraysForCodeEdit(param.type, param.default);
      }
    });
    setParams(initialParamValues);
  }, [newImport, importTypes, type]);

  const brandOptions = useMemo(() => {
    return brands.map((brand) => {
      return brand.id;
    });
  }, [brands]);

  const handleParamValueChange = (value: any, field: string) => {
    setParams({
      ...params,
      [field]: value,
    });
  };

  const handleSaveImport = () => {
    let otherParams = {} as any;
    if (params.compositeIdFields) {
      if (Array.isArray(params.compositeIdFields)) {
        otherParams.compositeIdFields = params.compositeIdFields;
      } else {
        otherParams.compositeIdFields = [...params.compositeIdFields.split(",")];
      }
    }

    const payload = {
      ...data,
      ...params,
      mapping: JSON.parse(mapping),
      ...otherParams,
    };

    dispatch(updateImport(importId, brandId, payload));
    handleClose();
  };

  const paramsForm = useMemo(() => {
    if (importTypes[type] === undefined) return "";
    return (
      <div className="params-container">
        {importTypes[type].map((param) => {
          return (
            <div className="param-container">
              <label className="param-label">{param.field}</label>
              {param.type === "boolean" ? (
                <ToggleSwitch
                  checked={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e, param.field);
                  }}
                />
              ) : param.type === "array" || param.type === "object" ? (
                <TextArea
                  value={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                />
              ) : (
                <Input
                  value={params[param.field]}
                  onChange={(e) => {
                    handleParamValueChange(e.target.value, param.field);
                  }}
                  fullWidth
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }, [params]);

  return (
    <div className="imports-modal-wrapper">
      <h1 className="imports-modal-title">{newImport ? "Create" : "Edit"} import</h1>
      <div className="imports-modal-container">
        <div className="column">
          {!newImport && <Input label="Id" value={importId} disabled separateLines fullWidth />}

          <div className="drop-select">
            <label htmlFor="importType">Type</label>
            <Select
              options={Object.keys(importTypes)}
              disabled={!newImport}
              selectedOption={type}
              setSelectedOption={(e) => {
                setType(e);
              }}
              styling={{
                selectOptionsContainerClassName: "options-dropdown",
              }}
              fullWidth
            />
          </div>
          <div className="drop-select">
            <label htmlFor="type">Brand Id</label>
            <Select
              options={brandOptions}
              disabled={!newImport}
              selectedOption={brandId}
              setSelectedOption={(e) => {
                setBrandId(e);
              }}
              styling={{
                selectOptionsContainerClassName: "options-dropdown",
              }}
              fullWidth
            />
          </div>
          <div className="switch-container">
            <ToggleSwitch
              checked={active}
              onChange={(e) => {
                setActive(e);
              }}
            />{" "}
            Active
          </div>
          {type && (
            <div className="drop-select">
              <label htmlFor="insertMethod">Insert Method</label>
              <Select
                options={InsertMethods}
                selectedOption={insertMethod}
                setSelectedOption={(e) => {
                  setInsertMethod(e);
                }}
                styling={{
                  selectOptionsContainerClassName: "options-dropdown",
                }}
                fullWidth
              />
            </div>
          )}
          <p>Mapping</p>
          <TextArea
            value={mapping}
            className="textarea-code-editor"
            onChange={(e) => {
              setMapping(e.target.value);
            }}
          />
        </div>
        <div className="column">{paramsForm}</div>
      </div>
      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            handleClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSaveImport();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default EditImportModal;
