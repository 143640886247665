import React from "react";
import Select from "../../ui/select/Select";

type CopyConfigurationProps = {
  showCopyConfiguration: boolean;
  handleToggleCopyConfiguration: Function;
};

const CopyConfiguration = ({ showCopyConfiguration, handleToggleCopyConfiguration }: CopyConfigurationProps) => {
  return (
    <div>
      <div
        className="brand-settings-title"
        onClick={() => {
          handleToggleCopyConfiguration();
        }}
      >
        Copy configuration
      </div>

      <div className={["brand-settings-container", showCopyConfiguration && "active"].join(" ")}>
        <div className="copy-configuration-wrapper">
          <p>Get notification algorithms from brand</p>
          <Select fullWidth options={["test"]} selectedOption={"test"} setSelectedOption={() => {}} />
          <p>Get crawlers configurations from brand</p>
          <Select fullWidth options={["test"]} selectedOption={"test"} setSelectedOption={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default CopyConfiguration;
