import React, { useCallback, useState } from "react";
import "./NotificationsSettingsModal.scss";
import ToolTip from "../ui/common/ToolTip";
import { useAppDispatch } from "../../store";
import { updateSubscriptions } from "../../store/slices/currentUser";

type TabsTableNavigationProps = {
  algorithmCategories: Array<any>;
  activeCategory: string;
  setActiveCategory: Function
};

type NotificationsSettingsModalProps = {
  algorithms: Array<any>;
  user: any;
};

const TabsTableNavigation = ({ algorithmCategories, activeCategory, setActiveCategory }: TabsTableNavigationProps) => {
  return (
    <div className="notification-settings-table-navigation">
      {algorithmCategories.map((e) => {
        return (
          <div
            className={[
              "notification-settings-table-nav-item",
              e.id === activeCategory ? "active" : null,
              e.items.length ? "disable" : null,
            ].join(" ")}
            onClick={() => { setActiveCategory(e) }}
            key={e.id}
          >
            {e.label}
            {e.items.length && <span className="notification-settings-table-badge">{e.items.length}</span>}
          </div>
        );
      })}
    </div>
  );
};

const NotificationsSettingsModal = ({ algorithms, user }: NotificationsSettingsModalProps) => {
  const [activeCategory, setActiveCategory] = useState("all");
  const dispatch = useAppDispatch();

  const setActiveAlgorithmCategory = (algorithmCategory) => {
    if (!algorithmCategory.disabled) {
      setActiveCategory(algorithmCategory.id);
    }
  };

  const toggleNotificationSelection = (algorithmType, subscriptionType) => {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

    if (subscriptionsData[subscriptionType].notificationTypes.indexOf(algorithmType) === -1) {
      subscriptionsData[subscriptionType].notificationTypes.push(algorithmType);
    } else {
      subscriptionsData[subscriptionType].notificationTypes.splice(subscriptionsData[subscriptionType].notificationTypes.indexOf(algorithmType), 1);
    }
    
    dispatch(updateSubscriptions(subscriptionsData));
  };

  const toggleBrandSelection = (brandId, subscriptionType) => {
    const subscriptionsData = JSON.parse(JSON.stringify(user.subscriptions));

    if (!subscriptionsData[subscriptionType].hasOwnProperty("brands")) {
      subscriptionsData[subscriptionType].brands = [];
    }

    if (subscriptionsData[subscriptionType].brands.indexOf(brandId) === -1) {
      subscriptionsData[subscriptionType].brands.push(brandId);
    } else {
      subscriptionsData[subscriptionType].brands.splice(subscriptionsData[subscriptionType].brands.indexOf(brandId), 1);
    }

    dispatch(updateSubscriptions(subscriptionsData));
  };

  const renderActiveCategoryAlgorithms = useCallback(() => {
    const activeCategoryAlgorithms = algorithms.find((algorithmCategory) => algorithmCategory.id === activeCategory);
    
    if (activeCategoryAlgorithms && activeCategoryAlgorithms.items) {
      return activeCategoryAlgorithms.items.map((categoryAlgorithm, index) => (
        <div className="notification-settings-table-item" key={categoryAlgorithm.id}>
          <div className="notification-settings-table-item-title">
            <p title={categoryAlgorithm.type}>{categoryAlgorithm.title}</p>
          </div>

          <div className="notification-settings-table-item-details">
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.mailNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${categoryAlgorithm.type}-mail`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.mailNotifications.active} message="First, activate mail in the previous screen">Mail</ToolTip>
              </label>
              <input type="checkbox"
                id={`${categoryAlgorithm.type}-mail`}
                disabled={!user.subscriptions.mailNotifications.active}
                onChange={() => { toggleNotificationSelection(categoryAlgorithm.type, 'mailNotifications') }}
                checked={user.subscriptions.mailNotifications.notificationTypes.indexOf(categoryAlgorithm.type) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.slackNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${categoryAlgorithm.type}-slack`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.slackNotifications.active} message="First, configure and activate Slack in the previous screen">Slack</ToolTip>
              </label>
              <input type="checkbox"
                id={`${categoryAlgorithm.type}-slack`}
                disabled={!user.subscriptions.slackNotifications.active}
                onChange={() => { toggleNotificationSelection(categoryAlgorithm.type, 'slackNotifications') }}
                checked={user.subscriptions.slackNotifications.notificationTypes.indexOf(categoryAlgorithm.type) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.googleChatNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${categoryAlgorithm.type}-gchat`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.googleChatNotifications.active} message="First, configure and activate Google Chat in the previous screen">
                  Google Chat
                </ToolTip>
              </label>
              <input type="checkbox"
                id={`${categoryAlgorithm.type}-gchat`}
                disabled={!user.subscriptions.googleChatNotifications.active}
                onChange={() => { toggleNotificationSelection(categoryAlgorithm.type, 'googleChatNotifications') }}
                checked={user.subscriptions.googleChatNotifications.notificationTypes.indexOf(categoryAlgorithm.type) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.teamsNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${categoryAlgorithm.type}-msteams`}>
                <div className="tooltip">
                  <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.teamsNotifications.active} message="First, configure and activate Microsoft Teams in the previous screen">
                    Microsoft Teams
                  </ToolTip>
                </div>
              </label>
              <input type="checkbox"
                id={`${categoryAlgorithm.type}-msteams`}
                disabled={!user.subscriptions.teamsNotifications.active}
                onChange={() => { toggleNotificationSelection(categoryAlgorithm.type, 'teamsNotifications') }}
                checked={user.subscriptions.teamsNotifications.notificationTypes.indexOf(categoryAlgorithm.type) !== -1}
              />
            </div>
          </div>
        </div>
      ));
    }
    

    return "";
  }, [algorithms, user, activeCategory]);

  const renderUserBrands = useCallback(() => {
    if (user.brands && user.brands.length) {
      return user.brands.map((brand, index) => (
        <div className="notification-settings-table-item" key={brand.id}>
          <div className="notification-settings-table-item-title">
            <p>{brand.name}</p>
          </div>

          <div className="notification-settings-table-item-details">
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.mailNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${brand.id}-mail`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.mailNotifications.active} message="First, activate mail in the previous screen">Mail</ToolTip>
              </label>
              <input type="checkbox"
                id={`${brand.id}-mail`}
                disabled={!user.subscriptions.mailNotifications.active}
                onChange={() => { toggleBrandSelection(brand.id, 'mailNotifications') }}
                checked={user.subscriptions.mailNotifications.brands && user.subscriptions.mailNotifications.brands.indexOf(brand.id) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.slackNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${brand.id}-slack`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.slackNotifications.active} message="First, configure and activate Slack in the previous screen">Slack</ToolTip>
              </label>
              <input type="checkbox"
                id={`${brand.id}-slack`}
                disabled={!user.subscriptions.slackNotifications.active}
                onChange={() => { toggleBrandSelection(brand.id, 'slackNotifications') }}
                checked={user.subscriptions.slackNotifications.brands && user.subscriptions.slackNotifications.brands.indexOf(brand.id) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.googleChatNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${brand.id}-gchat`}>
                <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.googleChatNotifications.active} message="First, configure and activate Google Chat in the previous screen">
                  Google Chat
                </ToolTip>
              </label>
              <input type="checkbox"
                id={`${brand.id}-gchat`}
                disabled={!user.subscriptions.googleChatNotifications.active}
                onChange={() => { toggleBrandSelection(brand.id, 'googleChatNotifications') }}
                checked={user.subscriptions.googleChatNotifications.brands && user.subscriptions.googleChatNotifications.brands.indexOf(brand.id) !== -1}
              />
            </div>
            <div className="notification-settings-table-detail">
              <label style={!user.subscriptions.teamsNotifications.active ? { 'color': 'grey' } : {}} htmlFor={`${brand.id}-msteams`}>
                <div className="tooltip">
                  <ToolTip position={index !== 0 ? 'top' : 'bottom'} disabled={user.subscriptions.teamsNotifications.active} message="First, configure and activate Microsoft Teams in the previous screen">
                    Microsoft Teams
                  </ToolTip>
                </div>
              </label>
              <input type="checkbox"
                id={`${brand.id}-msteams`}
                disabled={!user.subscriptions.teamsNotifications.active}
                onChange={() => { toggleBrandSelection(brand.id, 'teamsNotifications') }}
                checked={user.subscriptions.teamsNotifications.brands && user.subscriptions.teamsNotifications.brands.indexOf(brand.id) !== -1}
              />
            </div>
          </div>
        </div>
      ));
    }

    return "";
  }, [user])

  return (
    <div className="notification-settings">
      <h2>Which notifications do you want to receive?</h2>
      <div className="notification-settings-table">
        <TabsTableNavigation algorithmCategories={algorithms} activeCategory={activeCategory} setActiveCategory={setActiveAlgorithmCategory} />

        <div className="notification-settings-table-items">
          {renderActiveCategoryAlgorithms()}
        </div>
      </div>

      <h2>For which brands would you like to receive notifications?</h2>
      <div className="notification-settings-table">
        <div className="notification-settings-table-items">
          {renderUserBrands()}
        </div>
      </div>
    </div>
  );
};

export default NotificationsSettingsModal;
