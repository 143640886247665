import React, { ComponentPropsWithoutRef } from "react";
import "./Button.scss";
interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "success" | "danger" | "warning";
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  rounded?: boolean;
  className?: string;
  children: any;
  onClick?: () => void;
}

interface ButtonCircleProps extends ComponentPropsWithoutRef<"button"> {
  variant?: "default" | "success" | "danger" | "warning";
  className?: string;
  children: any;
  onClick?: () => void;
}

const Button = ({
  variant,
  size = "medium",
  fullWidth = false,
  children,
  className,
  rounded = true,
  ...restOfProps
}: ButtonProps) => {
  return (
    <button
      className={[
        "so-button",
        `so-button--${variant}`,
        `so-button--${size}`,
        `${rounded ? "so-button-rounded" : ""}`,
        `${fullWidth ? "so-button-fullwidth" : ""}`,
        className,
      ].join(" ")}
      {...restOfProps}
    >
      {children}
    </button>
  );
};

Button.Circle = ({ variant, children, className, ...restOfProps }: ButtonCircleProps) => {
  return (
    <button className={["so-button-circle", `so-button--${variant}`, className].join(" ")} {...restOfProps}>
      {children}
    </button>
  );
};

export default Button;
