import React, { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { StoriesIcon } from "../../icons/Icons";
import "./NavigationFilters.scss";
import { useAppDispatch } from "../../store";
import NavigationSearch from "./NavigationSearch";
import { useSelector } from "react-redux";
import { fetchSections } from "../../store/slices/sections";
import { CloseIcon } from "../../icons/Icons";
import Select from "react-select";

type Props = {
  title: string;
  filters?: any;
  filtersMessage: string;
  selectedFilter?: string;
  setSelectedFilter: Function;
  fetchFunction?: Function;
  fetchFunctionForSections?: Function;
  hasSearch?: boolean;
  hasSectionFilter?: boolean;
  searchTerm?: string;
  setSearchTerm?: Function;
  searchFunction?: Function;
  selectedSections?: Array<string>;
  setSelectedSections?: Function;
};

type BrandState = {
  currentBrand: {
    isLoading: boolean;
    error: Object | null;
    data: {
      features: Array<string>;
      domain: string;
    } | null;
  };
};

type Option = {
  value: string;
  label: string;
};

const NavigationFilters = ({
  title,
  filters,
  filtersMessage,
  selectedFilter,
  setSelectedFilter,
  fetchFunction,
  fetchFunctionForSections,
  hasSearch = false,
  hasSectionFilter = false,
  searchTerm,
  setSearchTerm = () => {},
  searchFunction = () => {},
  selectedSections,
  setSelectedSections,
}: Props) => {
  const [menuShown, setMenuShown] = useState(false);
  const [menuStyles, setMenuStyles] = useState({});
  const [sectionSelectValue, setSectionSelectValue]: [{ label: string; value: string }[], Function] = useState([]);

  const sections = useSelector((state: any) => state.sections.data);
  const isLoadingSections = useSelector((state: any) => state.sections.isLoading);
  const brandInfo = useSelector((state: BrandState) => state.currentBrand.data);

  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const isSectionFilterAllowed = () => {
    return hasSectionFilter && true;
    // return hasSectionFilter && brandInfo && ['fraenkischertag', 'berlinerzeitung', 'berlinerkurier', 'onet'].includes(brandInfo.domain);
  };

  useEffect(() => {
    if (brandInfo?.domain && isSectionFilterAllowed()) {
      dispatch(fetchSections("feed", brandInfo.domain));
    }
  }, [brandInfo]);

  useEffect(() => {
    if (selectedSections && sections.length > 0) {
      if (selectedSections && selectedSections.length > 0) {
        const sectionNames = selectedSections.map((selectedSectionValue) => {
          const selectedSection = sections.find((section) => section.id === selectedSectionValue);

          return selectedSection && selectedSection.id ? selectedSection.id.slice(1).replace(/\//g, " > ") : "";
        });

        setSearchParams((prevSearchParams) => {
          prevSearchParams.set("sectionFilters", sectionNames.join(","));
          return prevSearchParams;
        });
      } else {
        setSearchParams((prevSearchParams) => {
          prevSearchParams.delete("sectionFilters");
          return prevSearchParams;
        });
      }

      setSectionSelectValue(
        selectedSections.map((section) => {
          return {
            value: section,
            label: section.slice(1).replace(/\//g, " > "),
          };
        }),
      );
    }

    if (selectedFilter) {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set("filter", selectedFilter);
        return prevSearchParams;
      });
    } else {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete("filter");
        return prevSearchParams;
      });
    }

    if (searchTerm) {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.set("q", searchTerm);
        return prevSearchParams;
      });
    } else {
      setSearchParams((prevSearchParams) => {
        prevSearchParams.delete("q");
        return prevSearchParams;
      });
    }
  }, [selectedFilter, searchTerm, selectedSections, setSearchParams, sections]);

  const toggleMenu = () => {
    const newValue = !menuShown;

    setMenuShown(newValue);
    if (newValue === true) {
      setTimeout(() => {
        setMenuStyles((oldStyles) => ({
          ...oldStyles,
          overflow: "visible",
        }));
      }, 200);
    } else {
      setMenuStyles({ overflow: "hidden" });
    }
  };

  const handleSetFilters = (filter: string) => {
    setSelectedFilter(filter);
    setMenuShown(false);
    setMenuStyles({ overflow: "hidden" });
  };

  const handleSetFiltersDispatch = (filter: string) => {
    setSelectedFilter(filter);
    if (fetchFunction) {
      dispatch(fetchFunction(filter, searchTerm, selectedSections));
    }
    setMenuShown(false);
    setMenuStyles({ overflow: "hidden" });
  };

  const handleSectionsChange = (value: Option[]) => {
    const sections = !value || value.length < 1 ? [] : value.map((item) => item.value);
    if (setSelectedSections) {
      setSelectedSections(sections);
    }

    if (fetchFunctionForSections) {
      dispatch(fetchFunctionForSections(sections));
    } else if (fetchFunction) {
      dispatch(fetchFunction(selectedFilter, searchTerm, sections));
    }
  };

  const getSectionOptions = useMemo(() => {
    return sections.map((section) => ({
      label: section.id.slice(1).replace(/\//g, " > "),
      value: section.id,
    }));
  }, [sections]);

  const displayedTitle = () => {
    if (selectedFilter || searchTerm) {
      return (
        <>
          {selectedFilter ? (
            <span
              className="nav-filters-selected"
              onClick={() => {
                if (fetchFunction) {
                  dispatch(fetchFunction("page"));
                  setSelectedFilter("");
                } else {
                  setSelectedFilter("");
                }
                setMenuShown(false);
                setMenuStyles({ overflow: "hidden" });
              }}
            >
              {selectedFilter} <span className="nav-remove-filter">x</span>
            </span>
          ) : null}
          {searchTerm ? (
            <span
              className="nav-filters-selected"
              onClick={() => {
                if (fetchFunction) {
                  dispatch(fetchFunction("page"));
                  setSearchTerm("");
                } else {
                  setSearchTerm("");
                }
                setMenuShown(false);
                setMenuStyles({ overflow: "hidden" });
              }}
            >
              {searchTerm} <span className="nav-remove-filter">x</span>
            </span>
          ) : null}
        </>
      );
    } else {
      return title;
    }
  };

  return (
    <div className="nav-filters-container">
      <div className={`nav-filters-animation-container ${menuShown ? "active" : null}`}>
        <div>
          <div className={`nav-filters-menu ${menuShown ? "active" : null}`} style={menuStyles}>
            <p>{filtersMessage}</p>
            <div>
              {filters.map((e) => {
                return (
                  <span
                    className={`pill ${e.selected && "active"}`}
                    onClick={() => {
                      if (fetchFunction) {
                        handleSetFiltersDispatch(e.filter.toLowerCase());
                      } else {
                        handleSetFilters(e.filter.toLowerCase());
                      }
                    }}
                    key={e.filter}
                  >
                    {e.filter}
                  </span>
                );
              })}
            </div>

            {hasSearch && (
              <NavigationSearch
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                searchFunction={searchFunction}
                selectedFilter={selectedFilter}
                selectedSections={selectedSections}
              />
            )}

            {isSectionFilterAllowed() && (
              <div className="nav-filters-sections-container">
                <small>Search by sections:</small>
                <div className="nav-filters-sections">
                  <Select
                    isMulti
                    name="sections"
                    options={getSectionOptions}
                    value={sectionSelectValue}
                    className="basic-single"
                    classNamePrefix="so-select"
                    isLoading={isLoadingSections}
                    onChange={(option) => handleSectionsChange(option as Option[])}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="nav-filters-header">
        <div className="nav-filters-title">{displayedTitle()}</div>
        <div className={`icon ${menuShown ? "active" : null}`} role="button" onClick={toggleMenu}>
          {menuShown ? <CloseIcon /> : <StoriesIcon width="20" height="20" />}
        </div>
      </div>
    </div>
  );
};

export default NavigationFilters;
