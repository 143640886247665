import { createSlice } from "@reduxjs/toolkit";
import Api from "../api/Api";

type State = {
  data: Array<Object>;
  isLoading: boolean;
  error: Object | null;

  allBrandsData: Array<Object>;
  isLoadingAllBrands: boolean;
  errorAllBrands: Object | null;

  updatingBrand: boolean;
};

const INITIAL_STATE = {
  data: [],
  isLoading: true,
  error: null,

  allBrandsData: [],
  isLoadingAllBrands: true,
  errorAllBrands: null,

  updatingBrand: false
};

const brands = createSlice({
  name: "brands",
  initialState: INITIAL_STATE,
  reducers: {
    fetchBrandsStart: (state: State) => {
      state.isLoading = true;
    },
    fetchBrandsSuccess: (state: State, action) => {
      state.isLoading = false;
      state.data = action.payload;
    },
    fetchBrandsFailed: (state: State, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    fetchAllBrandsStart: (state: State) => {
      state.isLoadingAllBrands = true;
    },
    fetchAllBrandsSuccess: (state: State, action) => {
      state.isLoadingAllBrands = false;
      state.allBrandsData = action.payload;
    },
    fetchAllBrandsFailed: (state: State, action) => {
      state.isLoadingAllBrands = false;
      state.errorAllBrands = action.payload;
    },

    updateBrandStart: (state: State) => {
      state.updatingBrand = true;
    },
    updateBrandSuccess: (state) => {
      state.updatingBrand= false;
    },
    updateBrandFailed: (state: State, action) => {
      state.updatingBrand = false;
    }
  },
});

export default brands.reducer;

const {
  fetchBrandsStart,
  fetchBrandsSuccess,
  fetchBrandsFailed,
  fetchAllBrandsStart,
  fetchAllBrandsSuccess,
  fetchAllBrandsFailed,
  updateBrandStart,
  updateBrandSuccess,
  updateBrandFailed
} = brands.actions;

export const fetchBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchBrandsStart());
    const api = new Api();
    const result = await api.getBrands();
    dispatch(fetchBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchBrandsFailed(err));
  }
};

export const fetchAllBrands = () => async (dispatch: Function) => {
  try {
    dispatch(fetchAllBrandsStart());
    const api = new Api();
    const result = await api.getAllBrands();
    dispatch(fetchAllBrandsSuccess(result));
  } catch (err) {
    dispatch(fetchAllBrandsFailed(err));
  }
};

export const updateBrand = (brandId : string, payload : any) =>async (dispatch: Function) => {
  try {
    dispatch(updateBrandStart());
    const api = new Api();
    const result = await api.postUpdateBrand(brandId, payload)
    dispatch(updateBrandSuccess());
  } catch (err) {
    dispatch(updateBrandFailed(err))
  }
}
