import React, { useCallback, useMemo, useState } from "react";
import Input from "../../../ui/common/Input";
import TextArea from "../../../ui/common/TextArea";
import ToggleSwitch from "../../../ui/toggleSwitch/ToggleSwitch";
import Button from "../../../ui/common/Button";
import "./AiModal.scss";

const AiModal = ({ data, onSave, onClose }: any) => {
  const [clientId, setClientId] = useState(data.clientId.value || data.clientId.default);
  const [goals, setGoals] = useState(data.goals.value?.split(", ") || data.goals.default || []);
  const [northstars, setNorthstars] = useState(data.northstars.value?.split(", ") || data.northstars.default || []);
  const [publicationTimeFrom, setPublicationTimeFrom] = useState(
    data.publicationTimeFrom.value || data.publicationTimeTo.default,
  );
  const [publicationTimeTo, setPublicationTimeTo] = useState(
    data.publicationTimeTo.value || data.publicationTimeTo.default,
  );

  const allGoals = useMemo(() => {
    return ["metadata", "readability", "sentiment", "timeslots", "userneeds"];
  }, []);

  const allNorthstars = useMemo(() => {
    return ["attentiontime", "conversions", "pageviews", "social"];
  }, []);

  const handleGoalSwitch = useCallback(
    (clickedGoal) => {
      if (goals.includes(clickedGoal)) {
        setGoals(
          goals.filter((goal) => {
            return goal !== clickedGoal;
          }),
        );
      } else {
        setGoals([...goals, clickedGoal]);
      }
    },
    [goals],
  );

  const handleNorthstarSwitch = useCallback(
    (clickedNorthstar) => {
      if (northstars.includes(clickedNorthstar)) {
        setNorthstars(
          northstars.filter((northstar) => {
            return northstar !== clickedNorthstar;
          }),
        );
      } else {
        setNorthstars([...northstars, clickedNorthstar]);
      }
    },
    [northstars],
  );

  const handleSave = () => {
    const dataToSave = {
      clientId: {
        default: data.clientId.default,
        value: clientId,
      },
      goals: {
        default: data.goals.default,
        value: goals.join(", "),
      },
      northstars: {
        default: data.northstars.default,
        value: northstars.join(", "),
      },
      publicationTimeFrom: {
        default: data.publicationTimeFrom.default,
        value: publicationTimeFrom,
      },
      publicationTimeTo: {
        default: data.publicationTimeTo.default,
        value: publicationTimeTo,
      },
    };

    onSave(dataToSave);
  };

  return (
    <div className="ai-modal-wrapper">
      <h3>AI settings</h3>
      <Input label="Client Id" value={clientId} onChange={() => {}} separateLines />
      <p>Credit Deals</p>
      <div className="creadit-deals-container">
        <div className="starting-date-deal">
          <p>Starting date deal</p>
          <Button variant="success">Add credit deal</Button>
        </div>
        <div className="number-of-credits">
          <p>Number of credits</p>
        </div>
      </div>
      <div className="goals-northstarts-container">
        <div className="goals-container">
          <p>Goals</p>
          {allGoals.map((goal) => {
            return (
              <div className="switch-wrapper" key={goal}>
                <div className="switch-container">
                  <ToggleSwitch
                    checked={goals.includes(goal)}
                    onChange={() => {
                      handleGoalSwitch(goal);
                    }}
                  />
                </div>
                <div className="switch-title">{goal}</div>
              </div>
            );
          })}
        </div>
        <div className="northstart-container">
          <p>Northstars</p>
          {allNorthstars.map((northstar) => {
            return (
              <div className="switch-wrapper" key={northstar}>
                <div className="switch-container">
                  <ToggleSwitch
                    checked={northstars.includes(northstar)}
                    onChange={() => {
                      handleNorthstarSwitch(northstar);
                    }}
                  />
                </div>
                <div className="switch-title">{northstar}</div>
              </div>
            );
          })}
        </div>
      </div>

      <Input
        label="Publication Time From"
        value={publicationTimeFrom}
        onChange={(e) => {
          setPublicationTimeFrom(e.target.value);
        }}
        separateLines
      />
      <Input
        label="Publication Time To"
        value={publicationTimeTo}
        onChange={(e) => {
          setPublicationTimeTo(e.target.value);
        }}
        separateLines
      />

      <div className="sub-modal-actions">
        <Button
          variant="danger"
          onClick={() => {
            onClose();
          }}
        >
          Cancel
        </Button>
        <Button
          variant="success"
          onClick={() => {
            handleSave();
            onClose();
          }}
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default AiModal;
