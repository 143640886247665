import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import auth from "./slices/auth";
import users from "./slices/users";
import notifiers from "./slices/notifiers";
import imports from "./slices/imports";
import brands from "./slices/brands";
import bundles from "./slices/bundles";
import feed from "./slices/feed";
import activeFeedItem from "./slices/activeFeedItem";
import currentBrand from "./slices/currentBrand";
import mobile from "./slices/mobile";
import impact from "./slices/impact";
import abTests from "./slices/abTests";
import multibrand from "./slices/multibrand";
import currentUser from "./slices/currentUser";
import algorithms from "./slices/algorithms";
import credits from "./slices/credits";
import aiCredits from "./slices/aiCredits";
import umbrellaBrands from "./slices/umbrellaBrands";
import modalControll from "./slices/modalControll";
import statistics from "./slices/statistics";
import comments from "./slices/comments";
import sections from "./slices/sections";
import smartoctoAI from "./slices/smartoctoAI";
import { useDispatch } from "react-redux";

const persistConfig = {
  key: "auth",
  storage,
};

const reducer = {
  auth: persistReducer(persistConfig, auth),
  users,
  notifiers,
  imports,
  brands,
  feed,
  activeFeedItem,
  currentBrand,
  bundles,
  mobile,
  modalControll,
  impact,
  abTests,
  multibrand,
  currentUser,
  algorithms,
  credits,
  umbrellaBrands,
  aiCredits,
  smartoctoAI,
  statistics,
  comments,
  sections
};

const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
