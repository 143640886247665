import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import StoryReach from "./StoryReach";
import SocialScore from "./SocialScore";
import ConversionScore from "./ConversionScore";
import StreamTable from "./StreamTable";
import StoryValueEngine from "./StoryValueEngine";
import Numbers from "./Numbers";
import Button from "../ui/common/Button";
import Modal from "../ui/modal/Modal";
import StoryDate from "../utils/StoryDate";
import { mainGraphData } from "./__mocks__/mainGraphData";
import { formatDisplayDate } from "../../helpers/dateHelper";
import { CircleLoader } from "../../icons/Loaders";
import useIsFeatureAllowed from "../../customHooks/useIsFeatureAllowed";
import AddItemToBundleModal from "../../components/modals/AddItemToBundleModal/AddItemToBundleModal";
import { removeItemFromBundle } from "../../store/slices/bundles";
import { useAppDispatch } from "../../store";

import "./Story.scss";

type StoryProps = {
  type?: string,
};

const Story = ({ type }: StoryProps) => {
  const dispatch = useAppDispatch();
  const activeItem = useSelector((state: any) => state.activeFeedItem.feedItem);
  const loading = useSelector((state: any) => state.activeFeedItem.isLoading);

  const { paramsId } = useParams();
  const isFeatureAllowed = useIsFeatureAllowed();
  const [bundles, setBundles] = useState(null);
  const [modalShown, setModalShown] = useState(false);
  const bundlesAllowed = isFeatureAllowed("bundles");

  useEffect(() => {
    document.title = activeItem?.post?.title || "Smartocto";
  }, [activeItem]);

  const updateShownBundles = (bundles) => {
    setBundles(bundles);
  };

  const handleRemoveStoryFromBundle = (bundleId) => {
    dispatch(removeItemFromBundle(bundleId, activeItem?.post?.post_id, updateShownBundles));
  };

  const shouldShowConversionScore = useMemo(() => {
    return activeItem?.conversionGraph?.graphData?.length > 0;
  }, [activeItem]);

  const bundlesToMap = useMemo(() => {
    if (bundles !== null) return bundles;
    else if (activeItem?.post?.bundles && activeItem?.post?.bundles.length > 0) {
      return activeItem?.post?.bundles;
    }
    return [];
  }, [bundles, activeItem]);

  return (
    <div className="story-container">
      <Modal
        isOpen={modalShown}
        onClose={() => {
          setModalShown(false);
        }}
      >
        <AddItemToBundleModal
          postId={activeItem?.post?.post_id}
          updateShownBundles={updateShownBundles}
          closeModal={() => {
            setModalShown(false);
          }}
        />
      </Modal>

      <header>
        {loading ? (
          <div className="title-loader-container">
            <CircleLoader />
          </div>
        ) : (
          <>
            <p className="story-date">
              <StoryDate timestamp={activeItem?.post?.display_date} />
            </p>
            <h1>
              {activeItem?.post?.title && (
                <a target="_blank" href={activeItem.post.post_url} title={activeItem.post.title}>
                  {activeItem.post.title}
                </a>
              )}
            </h1>
            {activeItem?.post?.payedArticle ? <p className="paid-article">This is a paid article</p> : null}
          </>
        )}

        {(type === "stories" || type === "stream" || type === "inked") && bundlesAllowed && (
          <div className="bundles-control-container">
            {activeItem?.post?.bundles && activeItem?.post?.bundles.length > 0 ? (
              <div className="bundles-container">
                {bundlesToMap.map((bundle) => {
                  return (
                    <div className="bundle-pill">
                      <span className="bundle-name">
                        <Link to={`/bundles/${bundle.bundle_id}`}>{bundle.bundle_name}</Link>
                      </span>

                      <span
                        className="remove-bundle"
                        onClick={() => {
                          handleRemoveStoryFromBundle(bundle.bundle_id);
                        }}
                      >
                        X
                      </span>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <Button
              variant="success"
              onClick={() => {
                setModalShown(true);
              }}
            >
              +
            </Button>
          </div>
        )}
      </header>
      <main>
        <StoryReach
          mainGraphData={mainGraphData}
          circleGraphData={activeItem?.totals}
          circleLoading={loading}
          secondaryPosts={activeItem?.secondary_posts}
          currentPost={activeItem?.post}
          type={type}
        />
        <SocialScore
          engagementData={activeItem?.engagement}
          loading={loading}
          secondaryPosts={activeItem?.secondary_posts}
          type={type}
        />
        {shouldShowConversionScore && (
          <ConversionScore loading={loading} conversionsData={activeItem?.conversionGraph} />
        )}
        <StreamTable data={activeItem?.post?.timeline} post={activeItem?.post} loading={loading} />
        {type !== "videos" && (
          <StoryValueEngine
            author={activeItem?.post?.author}
            section={activeItem?.post?.section}
            reach={activeItem?.totals?.page_view_story_expectation}
            socialScore={activeItem?.engagement?.engagement_story_expectation}
            loading={loading}
          />
        )}
        <Numbers loading={loading} type={type} />
      </main>
    </div>
  );
};

export default Story;
