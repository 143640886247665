import React, { useEffect, useMemo, useState } from "react";
import Input from "../../ui/common/Input";
import Button from "../../ui/common/Button";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../store";
import { fetchBrands } from "../../../store/slices/brands";
import { CircleLoader } from "../../../icons/Loaders";
import useCheckUserPermissions from "../../../customHooks/useCheckUserPermissions";

import "./UserFormModal.scss";
import { addUser, editUser } from "../../../store/slices/users";

type UserFormModalProps = {
  user: {
    active: boolean;
    admin: boolean;
    superUser: boolean;
    email: string;
    firstName: string;
    lastName: string;
    brands: Array<string>;
  };
  setUserModalOpened: Function;
};

type FormErrors = {
  firstName?: string;
  lastName?: string;
  email?: string;
  active?: string;
  admin?: string;
  superUser?: string;
  apiToken?: string;
  brands?: string;
  root?: string;
};

const FILTERS = {
  ALL: "all",
  ASSIGNED: "assigned",
  NOT_ASSIGNED: "not_assigned",
};

const UserFormModal = ({ user, setUserModalOpened }: UserFormModalProps) => {
  const dispatch = useAppDispatch();
  const brands = useSelector((state: any) => state.brands.data);
  const brandsLoading = useSelector((state: any) => state.brands.isLoading);
  const isPosting = useSelector((state: any) => state.users.isPosting);
  const [activeFilter, setActiveFilter] = useState(FILTERS.ALL);
  const [searchTerm, setSerchTerm] = useState("");
  const checkUserPermissions = useCheckUserPermissions();

  const [formData, setFormData] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    email: user?.email || "",
    active: user?.active || true,
    admin: user?.admin || false,
    superUser: user?.superUser || false,
    apiToken: "",
    brands: user?.brands || [],
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name } = e.target;
    setFormData((data) => ({
      ...data,
      [name]: !data[name],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      try {
        if (user === null) {
          await dispatch(addUser(formData));
        } else {
          await dispatch(editUser(user.email, formData));
        }

        setUserModalOpened(false);
      } catch (error: any) {
        if (error?.response?.data?.message === "email already exists, please contact support@smartocto.com") {
          setErrors({ email: "Email already exists, please contact support@smartocto.com." });
        } else {
          setErrors({ root: "Unexpected error." });
        }
      }
    }
  };

  const validateForm = (data) => {
    const errors: FormErrors = {};

    if (!data.firstName.trim()) {
      errors.firstName = "First Name is required.";
    }

    if (!data.lastName.trim()) {
      errors.lastName = "Last Name is required.";
    }

    if (!data.email.trim()) {
      errors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = "Email is invalid.";
    }

    return errors;
  };

  const handleSearch = (e) => {
    setSerchTerm(e.target.value);
  };

  const filteredBrands = useMemo(() => {
    if (brandsLoading) return [];
    const filtered = brands.filter((brand) => {
      return (
        brand.name.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1 ||
        brand.id.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) > -1
      );
    });
    return filtered.sort((a, b) => {
      if (a.id < b.id) return -1;
      if (a.id > b.id) return 1;
      return 0;
    });
  }, [brands, searchTerm, brandsLoading]);

  const assignedBrands = useMemo(() => {
    if (brandsLoading) return [];
    let assigned = [] as Array<{ name: string; id: string }>;
    filteredBrands.forEach((brand) => {
      if (formData.brands.indexOf(brand.id) > -1) {
        assigned.push(brand);
      }
    });

    return assigned;
  }, [filteredBrands, formData.brands, brandsLoading]);

  const notAssignedBrands = useMemo(() => {
    if (brandsLoading) return [];
    let notAssigned = [] as Array<{ name: string; id: string }>;
    filteredBrands.forEach((brand) => {
      if (formData.brands.indexOf(brand.id) === -1) {
        notAssigned.push(brand);
      }
    });
    return notAssigned;
  }, [filteredBrands, formData.brands, brandsLoading]);

  const handleBrandToggle = (brandId) => {
    setFormData((data) => {
      const newBrands = [...data.brands];

      if (data.brands.includes(brandId)) {
        const index = newBrands.indexOf(brandId);
        if (index !== -1) {
          newBrands.splice(index, 1);
        }
      } else {
        newBrands.push(brandId);
      }

      return {
        ...data,
        brands: newBrands,
      };
    });
  };

  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  const currentlyDisplayedBrands = () => {
    if (activeFilter === FILTERS.ALL) {
      return filteredBrands.map((brand) => {
        return (
          <div className="brand-option" key={brand.id}>
            <input
              type="checkbox"
              defaultChecked={formData.brands?.indexOf(brand.id) > -1 ? true : false}
              onChange={() => {
                handleBrandToggle(brand.id);
              }}
            />
            <b>{brand.id}</b> - <i>{brand.name}</i>
          </div>
        );
      });
    } else if (activeFilter === FILTERS.ASSIGNED) {
      return assignedBrands.map((brand) => {
        return (
          <div className="brand-option" key={brand.id}>
            <input
              type="checkbox"
              defaultChecked={true}
              onChange={() => {
                handleBrandToggle(brand.id);
              }}
            />
            <b>{brand.id}</b> - <i>{brand.name}</i>
          </div>
        );
      });
    } else if (activeFilter === FILTERS.NOT_ASSIGNED) {
      return notAssignedBrands.map((brand) => {
        return (
          <div className="brand-option" key={brand.id}>
            <input
              type="checkbox"
              defaultChecked={false}
              onChange={() => {
                handleBrandToggle(brand.id);
              }}
            />
            <b>{brand.id}</b> - <i>{brand.name}</i>
          </div>
        );
      });
    }
  };

  return (
    <div className="user-modal-wrapper">
      <form onSubmit={handleSubmit}>
        <h1 className="modal-title">{user === null ? "Add user" : "Edit user"}</h1>

        <div className="user-modal-container">
          <div className="user-modal-details">
            <Input
              id="active"
              label="User Active"
              type="checkbox"
              name="active"
              checked={formData.active}
              onChange={handleCheckboxChange}
            />
            <br />
            <br />
            <Input
              id="email"
              label="Email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              separateLines
              fullWidth
              readOnly={user === null ? false : true}
              disabled={user === null ? false : true}
            />
            {errors.email && <div className="so-error">{errors.email}</div>}

            <Input
              id="firstName"
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              separateLines
              fullWidth
            />
            {errors.firstName && <div className="so-error">{errors.firstName}</div>}

            <Input
              id="lastName"
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              separateLines
              fullWidth
            />
            {errors.lastName && <div className="so-error">{errors.lastName}</div>}
            {checkUserPermissions("admin") && (
              <Input
                id="apiToken"
                label="API Token"
                name="apiToken"
                value={formData.apiToken}
                onChange={handleInputChange}
                separateLines
                fullWidth
              />
            )}
            {errors.apiToken && <div className="so-error">{errors.apiToken}</div>}
            {checkUserPermissions("admin") && (
              <Input
                id="isAdmin"
                label="Admin"
                type="checkbox"
                name="admin"
                checked={formData.admin}
                onChange={handleCheckboxChange}
              />
            )}
            <br />
            <br />
            <Input
              id="isSuperUser"
              label="Super User"
              type="checkbox"
              name="superUser"
              checked={formData.superUser}
              onChange={handleCheckboxChange}
            />
          </div>
          {brandsLoading ? (
            <div className="loader-container">
              <CircleLoader />
            </div>
          ) : (
            <div className="user-modal-brand-selector-container">
              <div className="user-modal-brand-selector-tabs">
                <div
                  className={[activeFilter === FILTERS.ALL && "active"].join()}
                  onClick={() => {
                    setActiveFilter(FILTERS.ALL);
                  }}
                >
                  All brands
                </div>
                <div
                  className={[activeFilter === FILTERS.ASSIGNED && "active"].join()}
                  onClick={() => {
                    setActiveFilter(FILTERS.ASSIGNED);
                  }}
                >
                  Assigned ({assignedBrands.length})
                </div>
                <div
                  className={[activeFilter === FILTERS.NOT_ASSIGNED && "active"].join()}
                  onClick={() => {
                    setActiveFilter(FILTERS.NOT_ASSIGNED);
                  }}
                >
                  Not Assigned
                </div>
              </div>
              <Input id="searchBrand" placeholder="Type to search brand" label="" onChange={handleSearch} fullWidth />
              <div className="user-modal-brand-selector">{currentlyDisplayedBrands()}</div>
            </div>
          )}
        </div>
        <div className="user-modal-submit">
          {errors.root && <div className="so-error">{errors.root}</div>}
          <Button
            variant="success"
            rounded={false}
            type="submit"
            disabled={isPosting || brandsLoading}
            className="user-modal-save-btn"
          >
            {isPosting ? "Loading..." : "Save changes"}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default UserFormModal;
